import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { formatNumbers } from "@/app/utils";
import { Loader, NoDataFound } from "@/components";

import { KpiTypeEnum } from "../../../../types";
import { KpiType } from "../../../../event.schema";

interface OSSTotalTileProps {
  isFetching: boolean;
  ossKPIs: KpiType[];
}

export const OSSTotalTile: FunctionComponent<OSSTotalTileProps> = ({ isFetching, ossKPIs }) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.ossKPIs.${key}`);

  const ossSticks =
    ossKPIs
      .filter((kpi) => kpi?.name === KpiTypeEnum.ONE_STICK_SAMPLING)
      .map((selectedKPI) => selectedKPI?.data?.at(0)?.overall?.value)
      ?.at(0)
      ?.toFixed(0) ||
    ossKPIs
      .filter((kpi) => kpi?.name === KpiTypeEnum.ONE_STICK_SAMPLING)
      .map((selectedKPI) => selectedKPI?.data?.at(0)?.date?.at(0)?.value)
      ?.at(0)
      ?.toFixed(0) ||
    "0";

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      px={1.5}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: ossSticks ? "fit-content" : 140,
        mb: { xs: 2, md: 0 },
        py: 1,
        width: "fit-content",
      }}
    >
      <Typography alignItems="center" display="flex" variant="gray14Bold">
        {ts("totalOSSTile.title")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : ossKPIs.length > 0 ? (
        <Box display="flex" flexDirection="column">
          <Typography variant="main42bold">{formatNumbers(ossSticks)}</Typography>
        </Box>
      ) : (
        <NoDataFound kpi={ts("totalOSSTile.title").toLocaleLowerCase()} />
      )}
    </Box>
  );
};
