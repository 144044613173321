import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Card } from "@mui/material";

import { RadialBarChart } from "@/components/RadialBarChart/RadialBarChart";
import { eventsSelector } from "@/pages/Private/redux";
import { useAppSelector } from "@/app/redux/hooks";

import { EventType } from "../../../event.schema";
import { EventStatusBox } from "../../components";
import { EventHeader } from "./EventHeader";
import { EventDuration } from "./EventDuration";

interface EventCardProps {
  event: EventType;
}

export const EventCard: FunctionComponent<EventCardProps> = ({ event }) => {
  const navigate = useNavigate();
  const { kpiTarget2TypeSwitchedOn } = useAppSelector(eventsSelector);

  const { id, startDate, endDate, status, kpiScore } = event;
  const noFinalizedKpi = event?.goals.some((goal) => goal.completed);

  return (
    <Card onClick={() => navigate(`${id}`)}>
      <Box borderBottom="1px solid" borderColor="gray400.main" display="flex" paddingBottom={1}>
        <EventHeader event={event} />
        <RadialBarChart
          isMobile={true}
          noFinalizedKpi={noFinalizedKpi}
          series={[
            kpiTarget2TypeSwitchedOn ? kpiScore?.planned2KpiScore : kpiScore?.plannedKpiScore,
          ]}
        />
      </Box>
      <Box
        sx={{
          paddingTop: 1,
          paddingBottom: 0.75,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <EventDuration
          dayArrCount={event.eventDates.length}
          endDate={endDate}
          startDate={startDate}
        />
        <EventStatusBox status={status} />
      </Box>
    </Card>
  );
};
