import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private/redux";
import { LoadingOverlay } from "@/components";
import { OnboardingTour } from "@/components/OnBoardingTour/OnBoardingTour";

import { PackSamplingTotalAndRealPackages } from "./components/PackSamplingTotalAndRealPackages";
import { PackSamplingTimeline } from "./components/PackSamplingTimeline";
import { PackSamplingByProductHeatmap } from "./components/PackSamplingByProductHeatmap";
import { PackSamplingProductsByBrand } from "./components/PackSamplingProductsByBrand";
import { TopTenProductTable } from "./components/TopTenProductTable";
import { TopTenModuleTable } from "./components/TopTenModulesTable";
import { PackSamplingByBrandDonutChart } from "./components/PackSamplingByBrandDonutChart";
import { PackSamplingByProductByModuleHeatmap } from "./components/PackSamplingByProductByModuleHeatmap";
import { EventNotes } from "../OverviewDashboard/components/EventNotes";
import { EventTypes } from "../../../types";
import { PackSamplingByDayDonutChart } from "./components/PackSamplingByDayDonutChart";
import { PackSamplingByCategoryDonutChart } from "./components/PackSamplingByCategoryDonutChart";

interface EventPackSamplingDashboardProps {
  eventDateId: number | null;
  selectedDateRange?: boolean;
  isFetching: boolean;
}
export const EventPackSamplingDashboard: FunctionComponent<EventPackSamplingDashboardProps> = ({
  eventDateId,
  selectedDateRange,
  isFetching,
}) => {
  const { t } = useTranslation();

  const { eventDetails, selectedEvent } = useAppSelector(eventsSelector);

  const isPloomEvent = eventDetails?.eventType !== EventTypes.FESTIVAL;
  const isPloomFestival = eventDetails?.eventType === EventTypes.PLOOM_FESTIVAL;

  const timeLineDates = useMemo(() => {
    if (eventDateId) {
      const eventDate = selectedEvent?.eventDates.find((date) => date.id === eventDateId);

      return {
        start: new Date(eventDate?.fromDateTime ?? ""),
        end: new Date(eventDate?.tillDateTime ?? ""),
      };
    }

    return {
      start: new Date(selectedEvent?.eventDates.map((date) => date.fromDateTime)[0] ?? ""),
      end: new Date(
        selectedEvent?.eventDates.map((date) => date.tillDateTime)[
          selectedEvent?.eventDates.length - 1
        ] ?? ""
      ),
    };
  }, [selectedEvent, eventDateId]);

  return !selectedEvent ? (
    <LoadingOverlay />
  ) : (
    <>
      <OnboardingTour isLoading={!selectedEvent?.data} />
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
          display: "flex",
          flexDirection: { xs: "column", md: isPloomFestival ? "column" : "row" },
          justifyContent: "space-between",
          gap: 0,
          width: "100%",
        }}
      >
        <PackSamplingTotalAndRealPackages
          isFetching={isFetching}
          packSamplingKPIs={selectedEvent?.data}
        />

        <Box
          bgcolor="white"
          borderRadius={2}
          boxShadow={1}
          p={1.5}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            pb: { xs: 1.5, md: 1 },
          }}
        >
          <>
            <PackSamplingByBrandDonutChart
              isFetching={isFetching}
              isFiltration={!!eventDateId}
              packSamplingKPIs={selectedEvent?.data}
            />
            <PackSamplingByDayDonutChart
              isFetching={isFetching}
              isFiltration={!!eventDateId}
              packSamplingKPIs={selectedEvent?.data}
            />
            <PackSamplingByCategoryDonutChart
              isFetching={isFetching}
              isFiltration={!!eventDateId}
              packSamplingKPIs={selectedEvent?.data}
            />
          </>
        </Box>
      </Box>
      <Box
        sx={{
          display: { md: "flex" },
          mb: { xs: 2, md: 1 },
        }}
      >
        <PackSamplingProductsByBrand
          isFetching={isFetching}
          isFiltration={!!eventDateId}
          packSamplingKPIs={selectedEvent?.data}
        />
        <TopTenProductTable
          isPloomEvent
          isDateRangeFiltering={selectedDateRange}
          isFetching={isFetching}
          isFiltration={!!eventDateId}
          packSamplingKPIs={selectedEvent?.data}
        />
      </Box>
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
        }}
      >
        {(!isPloomEvent || isPloomFestival) && (
          <PackSamplingByProductHeatmap
            isFiltration={!!eventDateId}
            isLoading={isFetching}
            kpis={selectedEvent.data}
            timezone={eventDetails.timezone}
          />
        )}
      </Box>
      {!isPloomEvent && (
        <>
          <Box borderBottom="2px solid" borderColor="gray400.main" my={2} width="100%">
            <Typography variant="main28bold">{t("events.moduleInsights")}</Typography>
          </Box>
          <Box
            sx={{
              display: { md: "flex" },
              mb: { xs: 2, md: 1 },
            }}
          >
            <PackSamplingTimeline
              dates={timeLineDates}
              eventDates={selectedEvent?.eventDates.map((eD) => eD.fromDateTime)}
              isFiltration={!!eventDateId}
              isLoading={isFetching}
              kpis={selectedEvent.data}
            />
            <TopTenModuleTable
              isFetching={isFetching}
              isFiltration={!!eventDateId}
              packSamplingKPIs={selectedEvent?.data}
            />
          </Box>
          <Box
            sx={{
              mb: { xs: 2, md: 1 },
            }}
          >
            <PackSamplingByProductByModuleHeatmap
              isFiltration={!!eventDateId}
              isLoading={isFetching}
              kpis={selectedEvent.data}
            />
          </Box>
          <EventNotes eventId={selectedEvent.id} notes={selectedEvent.notes} />
        </>
      )}
    </>
  );
};
