import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "@/app/redux/hooks";
import { projectsSelector } from "@/pages/Private/redux/projects.slice";
import { LoadingOverlay } from "@/components";
import { SalesTotalAndRealPackages } from "@/pages/Private/pages/Events/pages/EventDetails/SalesDashboard/components/SalesTotalAndRealPackages";
import { SoldProductsByBrand } from "@/pages/Private/pages/Events/pages/EventDetails/SalesDashboard/components/SalesProductsByBrand";
import { TopTenProductTable } from "@/pages/Private/pages/Events/pages/EventDetails/SalesDashboard/components/TopTenProductTable";
import { SalesByProductHeatmap } from "@/pages/Private/pages/Events/pages/EventDetails/SalesDashboard/components/SalesByProductHeatmap";
import { TopTenModuleTable } from "@/pages/Private/pages/Events/pages/EventDetails/SalesDashboard/components/TopTenModulesTable";
import { SalesByProductByModuleHeatmap } from "@/pages/Private/pages/Events/pages/EventDetails/SalesDashboard/components/SalesByProductByModuleHeatmap";
import { SalesByBrandDonutChart } from "@/pages/Private/pages/Events/pages/EventDetails/SalesDashboard/components/SalesByBrandDonutChart";
import { SalesTimeline } from "@/pages/Private/pages/Events/pages/EventDetails/SalesDashboard/components/SalesTimeline";
import { berlinTimeZone } from "@/app/utils";

export const ProjectByBrandSalesDashboard: FunctionComponent = () => {
  const { t } = useTranslation();

  const { selectedProjectByBrand: project } = useAppSelector(projectsSelector);

  const timeLineDates = useMemo(() => {
    const startDate = project?.eventDates[0]?.fromDateTime ?? "";
    const endDate = project?.eventDates[project.eventDates.length - 1]?.tillDateTime ?? "";
    const start = new Date(startDate);
    const end = new Date(endDate);

    return { start, end };
  }, [project]);

  return !project ? (
    <LoadingOverlay />
  ) : (
    <>
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
        width="100%"
      >
        <SalesTotalAndRealPackages isFetching={!project} salesKPIs={project?.data} />
        <SalesByBrandDonutChart isFetching={!project} salesKPIs={project?.data} />
      </Box>
      <Box
        sx={{
          display: { md: "flex" },
          mb: { xs: 2, md: 1 },
        }}
      >
        <SoldProductsByBrand isFetching={!project} salesKPIs={project?.data} />
        <TopTenProductTable isFetching={!project} salesKPIs={project?.data} />
      </Box>
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
        }}
      >
        <SalesByProductHeatmap
          isLoading={!project}
          kpis={project?.data}
          timezone={project?.eventDates?.[0]?.timezone ?? berlinTimeZone}
        />
      </Box>
      <Box borderBottom="2px solid" borderColor="gray400.main" my={2} width="100%">
        <Typography variant="main28bold">{t("events.moduleInsights")}</Typography>
      </Box>
      <Box
        sx={{
          display: { md: "flex" },
          mb: { xs: 2, md: 1 },
        }}
      >
        <SalesTimeline
          dates={timeLineDates}
          eventDates={project?.eventDates.map((eD) => eD.fromDateTime)}
          isLoading={!project}
          isProjectReporting={true}
          kpis={project?.data}
        />
        <TopTenModuleTable isFetching={!project} salesKPIs={project?.data} />
      </Box>
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
        }}
      >
        <SalesByProductByModuleHeatmap isLoading={!project} kpis={project?.data} />
      </Box>
    </>
  );
};
