import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { BackLink } from "@/components";
import { useCreateEventMutation } from "@/pages/Private/redux";
import { convertEventDates, notify, berlinTimeZone } from "@/app/utils";
import { defaultEventDate } from "@/app/constants";
import { useUploadImage } from "@/pages/Private/helpers/useUploadImage";

import { EventSettingForm } from "../components";
import { EventRequestBodyType, EventRequestType } from "../../event.schema";
import { EventTypes } from "../../types";

export const CreateEvent: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.create.${key}`);

  const [createEvent, { isLoading, isSuccess, isError }] = useCreateEventMutation();
  const { uploadImage, isLoading: isImageLoading } = useUploadImage();

  const initialValues: EventRequestBodyType = {
    eventType: "",
    name: "",
    externalId: "",
    companyId: 0,
    projectId: 0,
    brandId: 0,
    agencyId: 0,
    eventDates: [defaultEventDate],
    street: "",
    houseNumber: "",
    city: "",
    zip: "",
    nielsen: "",
    image: "",
    timezone: berlinTimeZone,
  };

  const onSubmit = async (values: EventRequestBodyType) => {
    const {
      name,
      externalId,
      projectId,
      brandId,
      agencyId,
      companyId,
      eventType,
      street,
      houseNumber,
      city,
      zip,
      nielsen,
      image,
      timezone,
    } = values;

    const eventDates =
      eventType === EventTypes.MOBILE ? [] : convertEventDates(values.eventDates, timezone);

    const body: EventRequestType = {
      name,
      ...(externalId && { externalId }),
      projectId: Number(projectId),
      brandId: Number(brandId),
      companyId: Number(companyId),
      agencyId: Number(agencyId),
      eventType,
      eventDates,
      street,
      houseNumber,
      city,
      zip,
      nielsen,
      timezone,
    };

    if (image) {
      const uploadedImage = await uploadImage(image);

      body.image = uploadedImage;
    }

    await createEvent(body);
  };

  useEffect(() => {
    if (isSuccess) {
      notify({ text: t("notifications.eventCreated") });
      navigate(-1);
    }

    if (isError) {
      notify({ text: t("notifications.wentWrong"), type: "error" });
    }
  }, [isSuccess, isError, navigate, t]);

  return (
    <Box pt={0.5}>
      <BackLink text={ts("backToEvents")} />
      <Typography color="gray900.main" component="h1" fontSize={24} fontWeight={600} my={3}>
        {ts("title")}
      </Typography>
      <EventSettingForm
        description={ts("description")}
        initialValues={initialValues}
        isLoading={isLoading || isImageLoading}
        onSubmit={onSubmit}
      />
    </Box>
  );
};
