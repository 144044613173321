import { FunctionComponent } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAppSelector } from "@/app/redux/hooks";
import { ROUTE_CONFIG } from "@/app/routes/config";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { Role } from "@/enum";

export const AdminAuth: FunctionComponent = () => {
  const { user } = useAppSelector(authSelector);
  const location = useLocation();

  const isBsureAdmin = user?.roles.some((role) => role.code === Role.BSURE_ADMIN);
  const isAgencyAdmin = user?.roles.some((role) => role.code === Role.AGENCY_ADMIN);
  const isCompaniesRoute = location.pathname.includes(ROUTE_CONFIG.COMPANIES);

  return isBsureAdmin || (isAgencyAdmin && !isCompaniesRoute) ? (
    <Outlet />
  ) : (
    <Navigate replace state={{ from: location }} to={ROUTE_CONFIG.EVENTS} />
  );
};
