import { FunctionComponent, useMemo } from "react";

import { formatNumbers } from "@/app/utils";

import { KpiTypeEnum, TopTenTableData } from "../../../../types";
import { KpiType } from "../../../../event.schema";
import { TopTenTable } from "./TopTenTable";

interface TopTenTableModuleProps {
  isFetching: boolean;
  packSamplingKPIs: KpiType[];
  isFiltration?: boolean;
  isPloomEvent?: boolean;
}

export const TopTenModuleTable: FunctionComponent<TopTenTableModuleProps> = ({
  isFetching,
  packSamplingKPIs,
  isFiltration,
  isPloomEvent,
}) => {
  const filteredPackSamplingModuleKPI = useMemo(() => {
    const packSamplingByProductProducts = packSamplingKPIs.find(
      (k) => k.name === KpiTypeEnum.PACK_SAMPLING_BY_MODULE
    )?.data;

    const dataArray: TopTenTableData[] = [];

    if (!packSamplingByProductProducts) {
      return dataArray;
    }

    for (const i of packSamplingByProductProducts) {
      const dates = isFiltration ? i.date : [i.overall];

      const item = {
        // Transform into SalesQuantity format
        name: i.specific ? i.specific : "",
        quantity: dates?.[0]?.value ? formatNumbers(dates?.[0].value.toFixed(0)) : 0,
        percentage: dates?.[0]?.percent ? dates?.[0].percent : 0,
      };

      dataArray.push(item);
    }

    dataArray.sort((a, b) => {
      if (a.percentage < b.percentage) {
        return 1;
      }

      if (a.percentage > b.percentage) {
        return -1;
      }

      return 0;
    });

    return dataArray;
  }, [isFiltration, packSamplingKPIs]);

  return (
    <TopTenTable
      dataToPass={filteredPackSamplingModuleKPI}
      isFetching={isFetching}
      namingPath="packSamplingModule"
    />
  );
};
