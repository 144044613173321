import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/app/redux";

import { AssignableAgenciesAndTenantsResponse } from "../pages/Users/schema/user";
import { usersApi } from "./users.api";

const initialState: AssignableAgenciesAndTenantsResponse = {
  agencies: [],
  tenants: [],
};

export const agenciesAndTenantsSlice = createSlice({
  name: "agenciesAndTenants",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addMatcher(
      usersApi.endpoints.getAssignableAgenciesAndTenants.matchFulfilled,
      (state, { payload }) => {
        state.agencies = payload.agencies;
        state.tenants = payload.tenants;
      }
    ),
});

export const agenciesAndTenantsSliceReducer = agenciesAndTenantsSlice.reducer;
export const agenciesAndTenantsSelector = (state: RootState) => state.agenciesAndTenants;
