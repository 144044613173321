import { useTranslation } from "react-i18next";
import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { getDay } from "date-fns";

import { DonutChart, Loader } from "@/components";
import { toPercent } from "@/app/utils";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface PackSamplingByDayProps {
  isFiltration?: boolean;
  isFetching: boolean;
  packSamplingKPIs: KpiType[];
}

export const PackSamplingByDayDonutChart: FunctionComponent<PackSamplingByDayProps> = ({
  isFiltration,
  isFetching,
  packSamplingKPIs,
}) => {
  const { t } = useTranslation();

  const packSamplingByDayLabels = useMemo(() => {
    const packSamplingByDayKPIs = packSamplingKPIs
      .find((kpi) => kpi.name === KpiTypeEnum.SAMPLES)
      ?.data.at(0);

    return (
      packSamplingByDayKPIs?.date?.reduce((weekDays: string[], kpi) => {
        const currDateArr = kpi.date.split("-");
        const currDayWeekIndex = getDay(
          new Date(+currDateArr[0], +currDateArr[1] - 1, +currDateArr[2])
        );
        const currWeekDayName = t(`weekDays.${currDayWeekIndex}`);

        weekDays.push(currWeekDayName);

        return weekDays;
      }, []) || []
    );
  }, [packSamplingKPIs, t]);

  const packSamplingByDayData = useMemo(() => {
    const packSamplingByDayKPIs = packSamplingKPIs
      .filter((kpi) => kpi.name === KpiTypeEnum.SAMPLES)
      .at(0)
      ?.data.at(0);

    const packSamplingByDayValues = packSamplingByDayKPIs?.date?.map((day) => day.value) ?? [];
    const total = packSamplingByDayValues.reduce((a, b) => a + b, 0);
    const percents = packSamplingByDayValues.map((v) =>
      Math.round(toPercent({ number: v, total }))
    );

    return percents;
  }, [packSamplingKPIs]);

  return isFiltration ? (
    <></>
  ) : (
    <Box
      sx={{
        borderStyle: "solid",
        borderWidth: { xs: "0 0 1px 0", md: "0 1px 0 0" },
        borderColor: "gray400.main",
        paddingX: { xs: 0, md: 1 },
        paddingY: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutSalesByDay")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutSalesByDay")}
          labels={packSamplingByDayLabels}
          series={packSamplingByDayData}
        />
      )}
    </Box>
  );
};
