import {
  AnyAction,
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit";

import { authReducer, authSlice } from "@/pages/Public/redux/auth.slice";
import { eventsReducer, eventsSlice } from "@/pages/Private/redux/events.slice";
import { sidebarReducer, sidebarSlice } from "@/pages/Private/redux/sidebar.slice";
import { brandsReducer, brandsSlice } from "@/pages/Private/redux/brands.slice";
import { companiesReducer, companiesSlice } from "@/pages/Private/redux/companies.slice";
import { modulesReducer, modulesSlice } from "@/pages/Private/redux/modules.slice";
import { dataSourcesReducer, dataSourcesSlice } from "@/pages/Private/redux/dataSources.slice";
import { projectsReducer, projectsSlice } from "@/pages/Private/redux/projects.slice";
import { joyrideReducer, joyrideSlice } from "@/pages/Private/redux/joyride.slice";
import {
  agenciesAndTenantsSlice,
  agenciesAndTenantsSliceReducer,
} from "@/pages/Private/redux/agencies.slice";

import { api } from "./api";
import { ROUTE_CONFIG } from "../routes/config";

const { LOGOUT } = ROUTE_CONFIG;

const rtkRejectQueryMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401) {
      if (!["login"].includes(action.meta.arg.endpointName)) {
        window.location.href = LOGOUT;
      }
    }
  }

  return next(action);
};

const appReducer = combineReducers({
  [authSlice.name]: authReducer,
  [api.reducerPath]: api.reducer,
  [eventsSlice.name]: eventsReducer,
  [sidebarSlice.name]: sidebarReducer,
  [brandsSlice.name]: brandsReducer,
  [companiesSlice.name]: companiesReducer,
  [modulesSlice.name]: modulesReducer,
  [dataSourcesSlice.name]: dataSourcesReducer,
  [projectsSlice.name]: projectsReducer,
  [joyrideSlice.name]: joyrideReducer,
  [agenciesAndTenantsSlice.name]: agenciesAndTenantsSliceReducer,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
  if (action.type === "auth/resetAuth") {
    state = undefined;
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(rtkRejectQueryMiddleware)
      .concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
