import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Button, ButtonSize, FilterButton, LoadingOverlay, SearchField, Tabs } from "@/components";
import { useSearch } from "@/app/utils";
import {
  eventsSelector,
  setActiveBar,
  useGetEventsQuery,
  useGetOverallKPIsForEventsQuery,
  useLazyGetOverallKPIsForEventsQuery,
} from "@/pages/Private/redux";
import { useHasUserPermission } from "@/app/hooks/useHasUserPermission";
import { ROUTE_CONFIG } from "@/app/routes/config";
import { PermissionRoles } from "@/enum";
import { ActiveBarEnum } from "@/types";
import { useAppSelector } from "@/app/redux/hooks";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { OnboardingTour } from "@/components/OnBoardingTour/OnBoardingTour";

import { EventCard, EventFilter, EventsTable } from "./components";
import {
  EventStatus,
  EventsOverallKPIDataResponse,
  KpiTypeEnum,
  NewsletterWithoutDeviceSpecifics,
  NewsletterWithDeviceSpecifics,
} from "../../types";
import { KpiSingleCard } from "./components/KpiSingleCard";
import { KpiTargetTypeSwitch } from "../components/KpiTargetTypeSwitch";
import { Features } from "../../../Companies/enums";

export const EventsOverview: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { filters, kpiTarget2TypeSwitchedOn } = useAppSelector(eventsSelector);
  const { user } = useAppSelector(authSelector);
  const [selectedTab, setSelectedTab] = useState<EventStatus | "all">("all");
  const is2ndKpiFeatureActive = useIsFeatureActive(Features.SECONDARY_PLANNED_KPI); // TECH DEBT - BA-706

  type overKpiType = {
    sales: EventsOverallKPIDataResponse | undefined;
    doi: EventsOverallKPIDataResponse | undefined;
    activations: EventsOverallKPIDataResponse | undefined;
    giveaway: EventsOverallKPIDataResponse | undefined;
    visitors: EventsOverallKPIDataResponse | undefined;
    samples: EventsOverallKPIDataResponse | undefined;
  };

  const [overallKpis, setOverallKpis] = useState<overKpiType>();

  const { searchValue, setSearchValue, page, setPage, limit } = useSearch();
  const [get] = useLazyGetOverallKPIsForEventsQuery();
  const { data, isLoading, isFetching } = useGetEventsQuery({
    page,
    limit,
    ...(selectedTab !== "all" && { status: selectedTab }),
    ...(searchValue && { query: searchValue }),
    ...(filters.brandId && { brandId: filters.brandId }),
    ...(filters.projectId && { projectId: filters.projectId }),
    ...(filters.eventTypeIds && { eventType: filters.eventTypeIds.join(",") || "" }),
  });

  const {
    data: dataKPIs,
    isLoading: isLoadingKPIs,
    isFetching: isFetchingKPIs,
  } = useGetOverallKPIsForEventsQuery({
    ...(filters.brandId && { brandId: filters.brandId }),
    ...(filters.projectId && { projectId: filters.projectId }),
  });

  useEffect(() => {
    get({
      ...(filters.brandId && { brandId: filters.brandId }),
      ...(filters.projectId && { projectId: filters.projectId }),
    });
  }, [filters, get]);

  //Changes the browser tab title dynamically
  useEffect(() => {
    window.document.title = t("windowTitles.eventListing");

    return () => {
      window.document.title = t("windowTitles.default");
    };
  }, [t]);

  useEffect(() => {
    if (dataKPIs && !isLoading) {
      const sales = dataKPIs.data.find(
        (dkpi) => dkpi.kpi === KpiTypeEnum.SALES && dkpi.specific === null
      );
      const salesGoals = kpiTarget2TypeSwitchedOn
        ? dataKPIs.kpiGoals[KpiTypeEnum.SALES_2] // TECH DEBT - BA-706
        : dataKPIs.kpiGoals[KpiTypeEnum.SALES];

      const doi = dataKPIs.data.reduce(
        (doiNlTypesSum, dkpi) => {
          if (
            (dkpi.kpi === KpiTypeEnum.NEWSLETTER_DATA &&
              dkpi.specific === NewsletterWithoutDeviceSpecifics.DOI_NL) ||
            (dkpi.kpi === KpiTypeEnum.NEWSLETTER_DATA &&
              dkpi.specific === NewsletterWithDeviceSpecifics.DOI_NL_WITH_DEVICE_ID)
          ) {
            doiNlTypesSum.sum += dkpi.sum;
            doiNlTypesSum.goal += dkpi.goal;
          }

          return doiNlTypesSum;
        },
        {
          kpi: KpiTypeEnum.NEWSLETTER_DATA,
          sum: 0,
          specific: NewsletterWithoutDeviceSpecifics.DOI_NL,
          goal: 0,
        }
      );
      const newsletterGoals = kpiTarget2TypeSwitchedOn
        ? dataKPIs.kpiGoals[KpiTypeEnum.NEWSLETTER_DATA_2] // TECH DEBT - BA-706
        : dataKPIs.kpiGoals[KpiTypeEnum.NEWSLETTER_DATA];
      const activations = dataKPIs.data.find(
        (dkpi) => dkpi.kpi === KpiTypeEnum.ACTIVATIONS && dkpi.specific === null
      );
      const activationsGoals = kpiTarget2TypeSwitchedOn
        ? dataKPIs.kpiGoals[KpiTypeEnum.ACTIVATIONS_2] // TECH DEBT - BA-706
        : dataKPIs.kpiGoals[KpiTypeEnum.ACTIVATIONS];
      const giveaway = dataKPIs.data.find(
        (dkpi) => dkpi.kpi === KpiTypeEnum.GIVEAWAY && dkpi.specific === null
      );
      const giveawayGoals = kpiTarget2TypeSwitchedOn
        ? dataKPIs.kpiGoals[KpiTypeEnum.GIVEAWAY_2] // TECH DEBT - BA-706
        : dataKPIs.kpiGoals[KpiTypeEnum.GIVEAWAY];
      const visitors = dataKPIs.data.find(
        (dkpi) => dkpi.kpi === KpiTypeEnum.VISITORS && dkpi.specific === null
      );
      const visitorsGoals = kpiTarget2TypeSwitchedOn
        ? dataKPIs.kpiGoals[KpiTypeEnum.VISITORS_2] // TECH DEBT - BA-706
        : dataKPIs.kpiGoals[KpiTypeEnum.VISITORS];
      const samples = dataKPIs.data.find(
        (dkpi) => dkpi.kpi === KpiTypeEnum.SAMPLES && dkpi.specific === null
      );
      const samplesGoals = kpiTarget2TypeSwitchedOn
        ? dataKPIs.kpiGoals[KpiTypeEnum.SAMPLES_2] // TECH DEBT - BA-706
        : dataKPIs.kpiGoals[KpiTypeEnum.SAMPLES];

      const addToOverallKpis = (
        overallKpiResponse: overKpiType,
        kpiName: string,
        kpi: EventsOverallKPIDataResponse,
        goal: number
      ) => {
        if (!kpi) {
          return overallKpiResponse;
        }

        return {
          ...overallKpiResponse,
          [kpiName]: {
            ...kpi,
            goal: goal,
          },
        };
      };

      let overallKpiResponse: overKpiType = {
        visitors: { kpi: KpiTypeEnum.VISITORS, sum: 0, specific: null, goal: 0 },
        sales: { kpi: KpiTypeEnum.SALES, sum: 0, specific: null, goal: 0 },
        doi: { kpi: KpiTypeEnum.NEWSLETTER_DATA, sum: 0, specific: null, goal: 0 },
        samples: { kpi: KpiTypeEnum.SAMPLES, sum: 0, specific: null, goal: 0 },
        activations: { kpi: KpiTypeEnum.ACTIVATIONS, sum: 0, specific: null, goal: 0 },
        giveaway: { kpi: KpiTypeEnum.GIVEAWAY, sum: 0, specific: null, goal: 0 },
      };

      overallKpiResponse =
        (visitors && addToOverallKpis(overallKpiResponse, "visitors", visitors, visitorsGoals)) ||
        overallKpiResponse;

      overallKpiResponse =
        (sales && addToOverallKpis(overallKpiResponse, "sales", sales, salesGoals)) ||
        overallKpiResponse;

      overallKpiResponse =
        (doi && addToOverallKpis(overallKpiResponse, "doi", doi, newsletterGoals)) ||
        overallKpiResponse;

      overallKpiResponse =
        (samples && addToOverallKpis(overallKpiResponse, "samples", samples, samplesGoals)) ||
        overallKpiResponse;

      overallKpiResponse =
        (activations &&
          addToOverallKpis(overallKpiResponse, "activations", activations, activationsGoals)) ||
        overallKpiResponse;

      overallKpiResponse =
        (giveaway && addToOverallKpis(overallKpiResponse, "giveaway", giveaway, giveawayGoals)) ||
        overallKpiResponse;

      setOverallKpis(overallKpiResponse);
    }
  }, [dataKPIs, isLoading, kpiTarget2TypeSwitchedOn]);

  const tabs = useMemo(() => {
    return [
      { text: t("events.allEvents"), value: "all" },
      { text: t("events.live"), value: EventStatus.LIVE },
      { text: t("events.upcoming"), value: EventStatus.UPCOMING },
      { text: t("events.finished"), value: EventStatus.FINISHED },
    ];
  }, [t]);

  return (
    <Box>
      <OnboardingTour isLoading={isLoading} />
      <Box alignItems="center" display="flex" mb={3}>
        <Box display="flex" flexGrow={1}>
          <Box
            display="flex"
            flexGrow={1}
            mr={2}
            sx={{
              maxWidth: { md: 320 },
            }}
          >
            <SearchField
              handleChange={(e) => setSearchValue(e.target.value)}
              name="search"
              placeholder={t("events.searchEvent")}
              value={searchValue}
            />
          </Box>
          <Box sx={{ flexGrow: { xs: 0, md: 1 } }}>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <EventFilter />
            </Box>
            <FilterButton
              activeFilters={
                Object.values(filters).filter(
                  (v) => v !== null && (Array.isArray(v) ? v.length > 0 : true)
                ).length
              }
              handleClick={() => dispatch(setActiveBar(ActiveBarEnum.EVENTS_FILTER))}
            />
          </Box>
        </Box>
        <Box sx={{ display: { xs: "none", lg: "flex" }, gap: 2 }}>
          {/* // TECH DEBT - BA-706 */}
          {is2ndKpiFeatureActive && <KpiTargetTypeSwitch />}
          {useHasUserPermission(PermissionRoles.CREATE_EVENTS) && (
            <Button
              handleClick={() => {
                navigate(ROUTE_CONFIG.EVENT_CREATE);
              }}
              size={ButtonSize.L}
              title={t("events.createEvent")}
            />
          )}
        </Box>
      </Box>
      {user?.company?.id === 1 && (
        <Box
          className="total-kpi-cards"
          mb={4}
          mt={2}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          {isLoadingKPIs || isFetchingKPIs ? (
            <LoadingOverlay />
          ) : (
            <>
              {overallKpis &&
                Object.values(overallKpis).map((current, key) => {
                  if (!current) return null;

                  return (
                    <KpiSingleCard
                      key={`kpiSingleCardKey-${key}`}
                      goal={current.goal}
                      kpi={current.kpi}
                      sum={current.sum}
                    />
                  );
                })}
            </>
          )}
        </Box>
      )}
      <Tabs
        handleSelect={(value) => setSelectedTab(value as EventStatus)}
        selectedTab={selectedTab}
        tabs={tabs}
      />
      <Box mt={2} sx={{ display: { md: "none" } }}>
        {data?.data?.length ? (
          data?.data.map((event) => <EventCard key={`event-card-${event.id}`} event={event} />)
        ) : (
          <Box mt={7} textAlign="center">
            <Typography fontSize={20} variant="gray14Bold">
              {t("basics.noData")}
            </Typography>
          </Box>
        )}
      </Box>
      <Box mt={2} sx={{ display: { xs: "none", md: "block" } }}>
        <EventsTable
          events={data?.data || []}
          handleFetchPage={setPage}
          isLoading={isLoading || isFetching}
          meta={data?.meta}
          page={page}
        />
      </Box>
    </Box>
  );
};
