import { default as Chart } from "react-apexcharts";
import { FunctionComponent } from "react";
import { Box, Tooltip, useTheme } from "@mui/material";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";

interface RadialBarChartProps {
  series: number[];
  isMobile?: boolean;
  noFinalizedKpi?: boolean;
  tooltipTitle?: string;
}

export const RadialBarChart: FunctionComponent<RadialBarChartProps> = ({
  series,
  isMobile,
  noFinalizedKpi,
  tooltipTitle,
}) => {
  const theme = useTheme();

  const colorRanges = [
    { from: 0, to: 10, color: theme.palette.radialBarColorway.gradient100 },
    { from: 10, to: 20, color: theme.palette.radialBarColorway.gradient200 },
    { from: 20, to: 30, color: theme.palette.radialBarColorway.gradient300 },
    { from: 30, to: 40, color: theme.palette.radialBarColorway.gradient400 },
    { from: 40, to: 50, color: theme.palette.radialBarColorway.gradient500 },
    { from: 50, to: 60, color: theme.palette.radialBarColorway.gradient600 },
    { from: 60, to: 70, color: theme.palette.radialBarColorway.gradient700 },
    { from: 70, to: 80, color: theme.palette.radialBarColorway.gradient800 },
    { from: 80, to: 90, color: theme.palette.radialBarColorway.gradient900 },
    { from: 90, to: 1000, color: theme.palette.radialBarColorway.gradient1000 },
  ];

  const getColorForValue = (value: number) => {
    const range = colorRanges.find((range) => value >= range.from && value < range.to);

    return range ? range.color : theme.palette.radialBarColorway.track;
  };

  return (
    <Box margin="0 auto" position="relative" width="fit-content">
      {!noFinalizedKpi && (
        <Box
          sx={{
            left: "75%",
            position: "absolute",
            top: "25%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
          }}
        >
          <Tooltip title={tooltipTitle}>
            <ReportProblemIcon sx={{ color: "warning.main" }} />
          </Tooltip>
        </Box>
      )}
      <Chart
        height={"100px"}
        options={{
          noData: {
            text: "#",
          },
          chart: {
            type: "radialBar",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "35%",
              },
              dataLabels: {
                name: { show: false },
                value: {
                  show: true,
                  fontWeight: 700,
                  offsetY: 5,
                  color: "#121212",
                  fontSize: "14px",
                  formatter: function (val) {
                    return parseInt(val.toString(), 10).toString();
                  },
                },
              },
              track: {
                background: theme.palette.radialBarColorway.track,
              },
            },
          },
          fill: {
            colors: [getColorForValue(series[0])],
          },
        }}
        series={series}
        type="radialBar"
        width={isMobile ? "50px" : "70px"}
      />
    </Box>
  );
};
