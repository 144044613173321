export const ROUTE_CONFIG = {
  DEFAULT: "/",
  LOGIN: "/login",
  LOGIN_BSURE: "/login-bsure",
  REGISTER: "/register",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgot-password",
  SET_PASSWORD: "/set-password",
  RESET_PASSWORD: "/reset-password",
  EVENTS: "/app/events",
  EVENT_CREATE: "/app/events/create",
  EVENT_DETAILS: "/app/events/:id",
  EVENT_SETTINGS: "/app/events/:id/settings",
  EVENT_PROMOTERS: "/app/events/:id/promoter",
  PROJECT_REPORTING: "/app/project-reporting",
  DYNAMIC_REPORTING: "/app/dynamic-reporting",
  USERS: "/app/users",
  BRANDS: "/app/brands",
  COMPANIES: "/app/companies",
  COMPANY_CREATE: "/app/companies/create",
  COMPANY_DETAILS: "/app/companies/:id",
  MODULES: "/app/modules",
  PROJECTS: "/app/projects",
  DATA_SOURCES: "/app/data-sources",
};
