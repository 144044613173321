import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { Bullets, LineChart, LineChartSeries, Loader, Tabs } from "@/components";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { Features } from "@/pages/Private/pages/Companies/enums";

import { EventDataType, KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface TimelineProps {
  kpis: KpiType[];
  dates: { start: Date; end: Date };
  eventDates: string[];
  isFiltration?: boolean;
  isLoading?: boolean;
  isProjectReporting?: boolean;
}

type chartType = "column" | "area" | "line";

export const OSSTimeline: FunctionComponent<TimelineProps> = ({
  kpis,
  dates,
  isLoading,
  eventDates,
  isProjectReporting,
}) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<KpiTypeEnum>(KpiTypeEnum.OSS_BY_MODULE);

  const isSalesGraphActive = useIsFeatureActive(Features.GRAPH_SALES);

  const tabs = useMemo(() => {
    const defaultTabs = [];

    if (isSalesGraphActive) {
      defaultTabs.push({
        text: t(`events.kpis.${KpiTypeEnum.OSS_BY_MODULE}`),
        value: KpiTypeEnum.OSS_BY_MODULE,
      });
    }

    return defaultTabs;
  }, [t, isSalesGraphActive]);

  const seriesData = useMemo(() => {
    let categories: number[] = [];
    const currentDataSet = kpis.find((k) => k.name === selectedTab);
    const data: LineChartSeries[] = currentDataSet
      ? currentDataSet.data.map((k) => {
          return {
            name: k.specific || "",
            type: "line",
            data: [],
          } as LineChartSeries;
        })
      : [];

    if (!currentDataSet) return { series: [], categories: [] };

    const firstDate = dates.start.getTime();
    const lastDate = dates.end.getTime();

    if (isProjectReporting) {
      categories = Array.from(
        new Set(
          data.flatMap((row) => {
            const kpiData: Partial<EventDataType> =
              (currentDataSet.data.find(
                (k) => k.specific === row.name
              ) as unknown as EventDataType) ?? [];
            const existedTimes = kpiData?.time || [];

            return existedTimes.map((o) => new Date(o.time || "").getTime());
          })
        )
      ).sort((a, b) => a - b);
    } else {
      for (let i = firstDate; i <= lastDate; i += 15 * 60 * 1000) {
        categories.push(i);
      }
    }

    const useTime = categories.length > eventDates.length;

    if (!useTime) {
      categories = eventDates.map((eD) => {
        const currentDate = new Date(eD);

        currentDate.setHours(0, 0, 0, 0);

        return currentDate.getTime();
      });
    }

    const series = data.map((row) => {
      const kpiData: Partial<EventDataType> =
        (currentDataSet.data.find((k) => k.specific === row.name) as unknown as EventDataType) ??
        [];
      const existedTimes = kpiData?.time || [];
      const existedDates = kpiData?.date || [];

      row.data = categories.map((unixMiliseconds) => {
        if (useTime) {
          const foundTime = existedTimes?.find(
            (time) => unixMiliseconds === new Date(time.time || "").getTime()
          );

          return foundTime ? +foundTime.value.toFixed(2) : 0;
        } else {
          const foundTime = existedDates?.find((date) => {
            const currentDate = new Date(date.date || "");

            currentDate.setHours(0, 0, 0, 0);

            return unixMiliseconds === currentDate.getTime();
          });

          return foundTime ? +foundTime.value.toFixed(2) : 0;
        }
      });

      return {
        ...row,
        name: t(`${row.name}`),
        type: !useTime ? ("column" as chartType) : ("line" as chartType),
      };
    });

    return { series, categories };
  }, [kpis, t, selectedTab, dates, eventDates, isProjectReporting]);

  return (
    <>
      {!isSalesGraphActive ? (
        <></>
      ) : (
        <Box
          sx={{
            mb: { xs: 3, md: 0 },
            mr: { xs: 0, md: 1 },
            bgcolor: { md: "white" },
            borderRadius: { md: 2 },
            boxShadow: { md: 1 },
            p: { md: 2 },
            width: { xs: "100%", md: "70%" },
          }}
        >
          <Box
            sx={{
              display: { md: "flex" },
              justifyContent: "space-between",
            }}
          >
            <Typography component="h3" variant="mainBold">
              {t("events.performanceTimeline")}
            </Typography>
            <Tabs
              excludeFromSearch
              handleSelect={(value) => setSelectedTab(value as KpiTypeEnum)}
              selectedTab={selectedTab}
              tabs={tabs}
            />
          </Box>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Box
                height={274}
                px={1.5}
                py={2}
                sx={{
                  bgcolor: { xs: "white", md: "transparent" },
                  borderRadius: { xs: 2, md: 0 },
                  boxShadow: { xs: 1, md: 0 },
                  mt: { xs: 2, md: 4 },
                }}
              >
                <LineChart
                  categories={seriesData.categories}
                  daysOnly={seriesData.categories.length <= eventDates.length}
                  series={seriesData.series}
                  stroke={[...seriesData.series.map(() => 2)]}
                />
              </Box>
              <Box sx={{ display: { md: "none" } }}>
                <Bullets
                  count={tabs.length}
                  selectedIndex={tabs.findIndex((t) => t.value === selectedTab)}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};
