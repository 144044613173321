import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { BackLink, LoadingOverlay, Tabs } from "@/components";
import { clearEventDetails, eventsSelector, useGetEventByIdQuery } from "@/pages/Private/redux";

import { ROUTE_CONFIG } from "@/app/routes/config";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { useAppSelector } from "@/app/redux/hooks";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { PermissionRoles } from "@/enum";

import { Features } from "../../../Companies/enums";
import { Budget, General, KPIs, Modules } from "./pages";
import { TourPlanUpload } from "./pages/TourPlanUpload/TourPlanUpload";
import { EventTypes } from "../../types";

export enum SettingTabs {
  GENERAL = "GENERAL",
  MODULES = "MODULES",
  TOUR_PLAN_UPLOAD = "TOUR_PLAN_UPLOAD",
  BUDGET = "BUDGET",
  KPIS = "KPIS",
}

export const EventSettings: FunctionComponent = () => {
  const { id = 0 } = useParams();
  const { t } = useTranslation();
  const ts = useCallback((key: string) => t(`events.settings.${key}`), [t]);

  const dispatch = useDispatch();
  const { isFetching } = useGetEventByIdQuery(Number(id), { refetchOnMountOrArgChange: true });
  const { user } = useAppSelector(authSelector);
  const { eventDetails } = useAppSelector(eventsSelector);
  const [selectedTab, setSelectedTab] = useState(SettingTabs.GENERAL);
  const [userPermittedToSeeBudgets, setUserPermittedToSeeBudgets] = useState(false);
  const isBudgetsActive = useIsFeatureActive(Features.BUDGETS_TAB);
  const isKPIsActive = useIsFeatureActive(Features.KPI_TAB);
  const userPermittedToSeeModules = user?.roles.map((role) =>
    role.permissions.some((permission) => permission.code === PermissionRoles.GET_MODULES)
  );

  useEffect(() => {
    const groupIds = user?.agency_ids || [];
    const isUserPermitted = groupIds.includes(eventDetails?.agencyId || 0);

    setUserPermittedToSeeBudgets(isUserPermitted);
  }, [user, eventDetails]);

  const tabs = useMemo(() => {
    const defaultTabs = [{ text: ts("tabs.general"), value: SettingTabs.GENERAL }];

    const activeFeaturesTabs = [];

    if (userPermittedToSeeBudgets && isBudgetsActive) {
      activeFeaturesTabs.push({ text: ts("tabs.budget"), value: SettingTabs.BUDGET });
    }

    if (userPermittedToSeeModules) {
      activeFeaturesTabs.push(
        eventDetails?.eventType === EventTypes.MOBILE
          ? { text: ts("tabs.tourPlanUpload"), value: SettingTabs.TOUR_PLAN_UPLOAD }
          : { text: ts("tabs.modules"), value: SettingTabs.MODULES }
      );
    }

    if (isKPIsActive) {
      activeFeaturesTabs.push({ text: ts("tabs.kpis"), value: SettingTabs.KPIS });
    }

    return activeFeaturesTabs.length > 0 ? [...defaultTabs, ...activeFeaturesTabs] : [];
  }, [
    ts,
    userPermittedToSeeBudgets,
    isBudgetsActive,
    isKPIsActive,
    userPermittedToSeeModules,
    eventDetails?.eventType,
  ]);

  const renderElement = useMemo(() => {
    switch (selectedTab) {
      case SettingTabs.GENERAL:
        return <General />;
      case SettingTabs.MODULES:
        return <Modules />;
      case SettingTabs.TOUR_PLAN_UPLOAD:
        return <TourPlanUpload />;
      case SettingTabs.BUDGET:
        return <Budget />;
      case SettingTabs.KPIS:
        return <KPIs />;

      default:
        return <General />;
    }
  }, [selectedTab]);

  //Changes the browser tab title dynamically
  useEffect(() => {
    window.document.title = t("windowTitles.eventSettings", { eventName: eventDetails?.name });

    return () => {
      window.document.title = t("windowTitles.default");
    };
  }, [t, eventDetails?.name]);

  useEffect(() => {
    return () => {
      dispatch(clearEventDetails());
    };
  }, [dispatch]);

  return isFetching ? (
    <LoadingOverlay />
  ) : (
    <>
      <BackLink destination={`${ROUTE_CONFIG.EVENTS}/${id}`} text={ts("backToEvent")} />
      <Typography color="gray900.main" component="h1" fontSize={24} fontWeight={600} mb={2} mt={3}>
        {ts("title")}
      </Typography>
      <Tabs
        excludeFromSearch
        handleSelect={(value) => setSelectedTab(value as SettingTabs)}
        selectedTab={selectedTab}
        tabs={tabs}
      />

      {renderElement}
    </>
  );
};
