import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";

import { ZodSchema } from "zod";

import { PARSED_ENV } from "@/app/constants/common";

interface FetchArgsWithSchemas extends FetchArgs {
  requestSchema?: ZodSchema;
  responseSchema?: ZodSchema;
}

export const API_REDUCER_KEY = "api";

const getRefreshToken = async (
  currentToken: string,
  tokenExpiry: string,
  refreshToken: string
): Promise<{
  newToken: string;
  newExpiry: number;
} | null> => {
  if (!currentToken || !tokenExpiry) return null;

  let newToken = currentToken;
  let newExpiry = parseInt(tokenExpiry);

  if (Date.now() > newExpiry - 60000 && refreshToken) {
    // 1 minute before expiry
    const response = await fetch(PARSED_ENV.REACT_APP_API_URL + "/api/users/refresh-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken }),
    });

    const data = await response.json();

    newToken = data.accessToken;
    newExpiry = Date.now() + data.expiresIn * 1000;
  }

  return {
    newToken,
    newExpiry,
  };
};

const baseQuery = fetchBaseQuery({
  baseUrl: PARSED_ENV.REACT_APP_API_URL + "/api",
  prepareHeaders: async (headers: Headers, { getState }) => {
    const currentToken = localStorage.getItem("token");
    const expiresIn = localStorage.getItem("expiresIn");
    const refreshToken = localStorage.getItem("refreshToken");
    const result = await getRefreshToken(currentToken!, expiresIn!, refreshToken!);
    const newToken = result?.newToken;
    const newExpiry = result?.newExpiry;

    if (result) {
      localStorage.setItem("token", newToken!);
      localStorage.setItem("expiresIn", newExpiry!.toString());
    }

    if (newToken) {
      headers.set("authorization", `Bearer ${newToken}`);
    }

    return headers;
  },
});

const baseQueryWithValidation: BaseQueryFn<
  FetchArgsWithSchemas,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  args.requestSchema?.parse(args.body);

  const result = await baseQuery(args, api, extraOptions);

  try {
    await args.responseSchema?.parseAsync(result.data);
  } catch (err) {
    // console.error(`"${args.url}" response schema validation error\n`, err);
  }

  return result;
};

export const api = createApi({
  reducerPath: API_REDUCER_KEY,
  baseQuery: baseQueryWithValidation,
  tagTypes: ["Users", "Brands", "Events", "Companies", "Modules", "Projects", "DataSources"],
  endpoints: () => ({}),
});
