import { FunctionComponent, useMemo } from "react";
//import { useTranslation } from "react-i18next";

import { formatNumbers } from "@/app/utils";

import { KpiTypeEnum, TopTenTableData } from "../../../../types";
import { KpiType } from "../../../../event.schema";
import { TopTenTable } from "./TopTenTable";

interface TopTenProductProps {
  isFetching: boolean;
  packSamplingKPIs: KpiType[];
  isFiltration?: boolean;
  isPloomEvent?: boolean;
  isDateRangeFiltering?: boolean;
}

export const TopTenProductTable: FunctionComponent<TopTenProductProps> = ({
  isFetching,
  packSamplingKPIs,
  isFiltration,
  isPloomEvent,
  isDateRangeFiltering,
}) => {
  const filteredPackSamplingProductKPI = useMemo(() => {
    const packSamplingProductsData = packSamplingKPIs.find(
      (k) => k.name === KpiTypeEnum.PACK_SAMPLING_BY_PRODUCT
    )?.data;

    const labels: TopTenTableData[] = [];

    if (!packSamplingProductsData) {
      return labels;
    }

    if (isFiltration && !packSamplingProductsData.at(0)?.date) {
      return labels;
    }

    for (const product of packSamplingProductsData) {
      const dates = product.date.filter((p) => isFiltration || isPloomEvent || p.date === null);

      if (dates.length === 0) {
        continue;
      }

      const item = {
        // Transform into SalesQuantity format
        name: product.specific ?? "",
        quantity: formatNumbers(
          ((isDateRangeFiltering ? product?.overall?.value : dates?.[0]?.value) ?? 0).toFixed(0)
        ),
        percentage: (isDateRangeFiltering ? product?.overall?.percent : dates?.[0]?.percent) ?? 0,
      };

      labels.push(item);
    }

    labels.sort((a, b) => {
      if (a.percentage < b.percentage) {
        return 1;
      }

      if (a.percentage > b.percentage) {
        return -1;
      }

      return 0;
    });

    return labels;
  }, [isPloomEvent, isDateRangeFiltering, packSamplingKPIs, isFiltration]);

  return (
    <TopTenTable
      dataToPass={filteredPackSamplingProductKPI}
      isFetching={isFetching}
      namingPath="packSamplingProduct"
    />
  );
};
