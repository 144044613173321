import { useTranslation } from "react-i18next";
import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface PackSamplingByCategoryProps {
  isFiltration?: boolean;
  isFetching: boolean;
  packSamplingKPIs: KpiType[];
}

export const PackSamplingByCategoryDonutChart: FunctionComponent<PackSamplingByCategoryProps> = ({
  isFetching,
  packSamplingKPIs,
}) => {
  const { t } = useTranslation();

  const packSamplingByCategoryLabels = useMemo(() => {
    const packSamplingByCategoryKPIs =
      packSamplingKPIs.find((kpi) => kpi.name === KpiTypeEnum.PACK_SAMPLING_BY_CATEGORY)?.data ??
      [];

    return packSamplingByCategoryKPIs.map((kpi) => kpi.specific);
  }, [packSamplingKPIs]);

  const packSamplingByCategoryData = useMemo(() => {
    const packSamplingByCategoryKPIs =
      packSamplingKPIs.find((kpi) => kpi.name === KpiTypeEnum.PACK_SAMPLING_BY_CATEGORY)?.data ??
      [];

    return packSamplingByCategoryLabels.map((type) => {
      const kpiData = packSamplingByCategoryKPIs.find((kpi) => kpi.specific === type);

      if (kpiData) {
        return Math.round(kpiData.date?.[0]?.percent ?? 0);
      } else {
        return 0;
      }
    });
  }, [packSamplingKPIs, packSamplingByCategoryLabels]);

  return (
    <Box
      sx={{
        paddingLeft: { xs: 0, md: 1 },
        paddingY: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutSalesByCategory")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutSalesByCategory")}
          labels={packSamplingByCategoryLabels}
          series={packSamplingByCategoryData}
        />
      )}
    </Box>
  );
};
