import { z } from "zod";

import { ERROR_TYPE } from "@/app/utils/errors";
import { PermissionRoles, Role, UserStatus } from "@/enum";
import { CompanyUserSchema } from "@/pages/Private/pages/Companies/schema/companies.schema";

const { REQUIRED, INVALID_EMAIL, INVALID_VALUE } = ERROR_TYPE;

// TODO: Refactor towards a consistent naming schema!
// Proposal: Let all ZodObjects begin with the letter Z, then remove the Z from the inferred type. Ex:
//
// const ZFoo = z.object(...);
// type Foo = z.infer<typeof Foo>;

export const LoginSchema = z.object({
  email: z.string().min(1, { message: REQUIRED }).email(INVALID_EMAIL),
  password: z.string().min(1, { message: REQUIRED }).trim(),
});

export type LoginType = z.infer<typeof LoginSchema>;

export const LoginRequestSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8, { message: INVALID_VALUE }),
});

export type TLoginRequest = z.infer<typeof LoginRequestSchema>;

const ZPermission = z.object({
  id: z.number().gt(0),
  name: z.string().min(1),
  code: z.nativeEnum(PermissionRoles),
  description: z.string().min(1),
});
const ZUserRole = z.object({
  id: z.number().gt(0),
  name: z.string().min(1),
  code: z.nativeEnum(Role),
  description: z.string().min(1),
  permissions: z.array(ZPermission),
});
const ZUserGroup = z.object({
  id: z.number().gt(0),
  name: z.string().min(1),
  companyId: z.number(),
  company: z.string().optional(),
  users: z.array(z.null()).optional(),
  events: z.array(z.string()).optional(),
});

export const FetchMeResponseSchema = z.object({
  id: z.number().gt(0),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  isBlocked: z.boolean(),
  status: z.nativeEnum(UserStatus).optional(),
  roles: z.array(ZUserRole),
  groups: z.array(ZUserGroup),
  createdAt: z.string().datetime().optional(),
  updatedAt: z.string().datetime().optional(),
  guide: z.object({
    events: z.object({ isCompleted: z.boolean(), stepIndex: z.number() }),
    eventDetails: z.object({ isCompleted: z.boolean(), stepIndex: z.number() }),
    eventSettings: z.object({ isCompleted: z.boolean(), stepIndex: z.number() }),
  }),
  tenant_id: z.number(),
  agency_id: z.number(),
  agency_ids: z.array(z.number()),
});

export const LoginResponseSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
  expiresIn: z.number(),
  user: FetchMeResponseSchema,
});

export type TLoginResponse = z.infer<typeof LoginResponseSchema>;
export type UserRole = z.infer<typeof ZUserRole>;
export type User = z.infer<typeof FetchMeResponseSchema>;
export type UserTable = User & {
  company?: z.infer<typeof CompanyUserSchema>;
};
