import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { formatNumbers } from "@/app/utils";
import { Loader, NoDataFound } from "@/components";

import { KpiTypeEnum } from "../../../../types";
import { KpiType } from "../../../../event.schema";

interface PackSamplingTotalAndRealPackagesProps {
  isFetching: boolean;
  packSamplingKPIs: KpiType[];
}

export const PackSamplingTotalAndRealPackages: FunctionComponent<
  PackSamplingTotalAndRealPackagesProps
> = ({ isFetching, packSamplingKPIs }) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.packSamplingKPIs.${key}`);

  const twentySticksPackages =
    packSamplingKPIs
      .filter((kpi) => kpi?.name === KpiTypeEnum.PACK_SAMPLING_20_STICK_PACKAGES)
      .map((selectedKPI) => selectedKPI?.data?.at(0)?.overall?.value)
      ?.at(0)
      ?.toFixed(0) ||
    packSamplingKPIs
      .filter((kpi) => kpi?.name === KpiTypeEnum.PACK_SAMPLING_20_STICK_PACKAGES)
      .map((selectedKPI) => selectedKPI?.data?.at(0)?.date?.at(0)?.value)
      ?.at(0)
      ?.toFixed(0) ||
    "0";

  const realPackages =
    packSamplingKPIs
      .filter((kpi) => kpi?.name === KpiTypeEnum.PACK_SAMPLING_REAL_PACKAGES)
      .map((selectedKPI) => selectedKPI?.data.at(0)?.overall?.value)
      ?.at(0) ||
    packSamplingKPIs
      .filter((kpi) => kpi?.name === KpiTypeEnum.PACK_SAMPLING_REAL_PACKAGES)
      .map((selectedKPI) => selectedKPI?.data?.at(0)?.date?.at(0)?.value)
      ?.at(0) ||
    "0";

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      px={1.5}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: { xs: 160, md: 140 },
        mb: { xs: 2, md: 0 },
        py: 1,
        width: "256px",
      }}
    >
      <Typography alignItems="center" display="flex" variant="gray14Bold">
        {ts("totalPacksTile.title")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : packSamplingKPIs.length > 0 ? (
        <>
          <Box display="flex" flexDirection="column">
            <Typography variant="main42bold">{formatNumbers(twentySticksPackages)}</Typography>
            <Typography variant="gray12">{t("events.twentySticks")}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="main14">{formatNumbers(realPackages)}</Typography>
            <Typography variant="gray12">{ts("totalPacksTile.real")}</Typography>
          </Box>
        </>
      ) : (
        <NoDataFound kpi={ts("totalPacksTile.title").toLocaleLowerCase()} />
      )}
    </Box>
  );
};
