import { FunctionComponent, useCallback, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  LinearProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { TargetIcon } from "@/assets";
import { formatNumbers } from "@/app/utils/helpers";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private";
import { toPercent } from "@/app/utils";
import { Loader, NoDataFound, TableColumn } from "@/components";
import { kpiSpecificPlaceholder } from "@/app/constants";

import { KpiType } from "../../../../event.schema";
import {
  KpiTypeEnum,
  KpiTileData,
  NewsletterWithoutDeviceSpecifics,
  EventTypes,
  NewsletterWithDeviceSpecifics,
  NewsletterSpecifics,
} from "../../../../types";

interface KpiCardProps {
  kpi: KpiType;
  kpiName: KpiTypeEnum;
  isForDesktop?: boolean;
  isFiltration?: boolean;
  showOverall?: boolean;
  isLoading?: boolean;
  dashboardHasKpiCard?: boolean;
}

export const KpiCard: FunctionComponent<KpiCardProps> = ({
  kpi,
  kpiName,
  isForDesktop,
  isFiltration,
  showOverall,
  isLoading,
  dashboardHasKpiCard,
}) => {
  const { t } = useTranslation();
  const ts = useCallback((key: string) => t(`events.kpis.${key}`), [t]);

  const isWithoutData = !kpi.data.length;

  const { selectedEvent, eventDetails, kpiTarget2TypeSwitchedOn } = useAppSelector(eventsSelector); // TECH DEBT - BA-706
  const kpiHiddenPlanned = selectedEvent?.brand?.company.config?.kpiHiddenPlanned;

  const isPloomEvent = eventDetails?.eventType !== EventTypes.FESTIVAL;
  const isPloomFestival = eventDetails?.eventType === EventTypes.PLOOM_FESTIVAL;
  const isNewsLetter = kpi.name === KpiTypeEnum.NEWSLETTER_DATA;

  const splitValue = useMemo(() => {
    if (isNewsLetter) {
      const doiNl = kpi?.data.filter((i) =>
        isPloomEvent
          ? isPloomFestival
            ? i.specific === NewsletterSpecifics.DOI_NL ||
              i.specific === NewsletterSpecifics.DOI_NL_WITH_DEVICE_ID
            : i.specific === NewsletterWithDeviceSpecifics.DOI_NL_WITH_DEVICE_ID
          : i.specific === NewsletterWithoutDeviceSpecifics.DOI_NL
      );

      const doiNlValue = isPloomEvent
        ? isFiltration
          ? doiNl?.reduce((total, doiNlType) => {
              total += doiNlType?.date?.[0]?.value;

              return total;
            }, 0)
          : doiNl?.reduce((total, doiNlType) => {
              total += doiNlType?.overall?.value;

              return total;
            }, 0)
        : isFiltration
        ? doiNl?.[0]?.date?.[0].value
        : doiNl?.[0]?.overall?.value;

      return formatNumbers(doiNlValue || 0).split(".");
    }

    const data = kpi?.data.find((i) => i.specific === null);
    const value = !isFiltration ? data?.overall?.value : data?.date?.[0].value;

    if (value) {
      return value < 10 ? [value.toString()] : formatNumbers(value || 0).split(".");
    }

    return ["0"];
  }, [kpi, isFiltration, isNewsLetter, isPloomEvent, isPloomFestival]);

  const percentReached = useMemo(() => {
    if (!kpi?.data.length) {
      return 0;
    }

    const actual = Number(splitValue.join(""));

    if (actual) {
      const percent = toPercent({
        number: actual,
        total: kpiTarget2TypeSwitchedOn ? kpi?.target2 : kpi?.target, // TECH DEBT - BA-706
      });

      if (percent >= 100) {
        return 100;
      }

      return Math.round(percent) !== percent ? +percent.toFixed(2) : percent;
    }
  }, [kpi?.data, kpi?.target, kpi?.target2, kpiTarget2TypeSwitchedOn, splitValue]);

  const data = useMemo(() => {
    const kpiSpecifics = kpi?.data
      .filter((i) => i.specific !== null)
      .reduce((acc: KpiTileData[], item) => {
        const { specific, overall, date } = item;

        if (specific) {
          acc.push({
            name: specific,
            value: (!isFiltration ? overall?.value : date?.[0].value) ?? 0,
            percent: (!isFiltration ? overall?.percent : date?.[0].percent) ?? 0,
          });
        }

        return acc;
      }, []);

    const newsletterSpecifics = Object.keys(
      isPloomEvent ? NewsletterSpecifics : NewsletterWithoutDeviceSpecifics
    ).map((key) => {
      const foundKpi = kpi?.data.find((i) => i.specific === key);

      return {
        name: ts(`specific.${key}`),
        value: (!isFiltration ? foundKpi?.overall?.value : foundKpi?.date?.[0].value) ?? "0",
        percent: (!isFiltration ? foundKpi?.overall?.percent : foundKpi?.date?.[0].percent) ?? "0",
      };
    });

    const specifics = isNewsLetter ? newsletterSpecifics : kpiSpecifics;

    // if there are less than 4 specifics, we need to add placeholders to fill the table
    if (specifics.length < 4 && showOverall) {
      const placeholderData = Array.from(Array(4 - specifics.length)).map(
        () => kpiSpecificPlaceholder
      );

      specifics.push(...placeholderData);
    }

    if (showOverall) {
      const all = {
        name: ts("specific.ALL"),
        value: 0,
        percent: 0,
      };

      for (const { value, percent } of specifics) {
        all.value += Number(value);
        all.percent += Number(percent);
      }

      if (all.percent > 100) {
        all.percent = 100;
      } else if (Number(all.percent) === all.percent && all.percent % 1 !== 0) {
        all.percent = Number(all.percent.toFixed(2));
      }

      specifics.push(all);
    }

    return showOverall
      ? specifics
      : [...specifics]
          .sort((a, b) => {
            return a.value < b.value ? 1 : 0;
          })
          .slice(0, 5);
  }, [kpi?.data, showOverall, isFiltration, ts, isNewsLetter, isPloomEvent]);

  const kpiColumns: TableColumn<KpiTileData>[] = [
    {
      title: "",
      width: 25,
      render: (row, opt) => (
        <Typography fontWeight={opt?.isLast ? 700 : 500} variant="main10Bold">
          {row.name ?? ""}
        </Typography>
      ),
    },
    {
      align: "center",
      title: "",
      width: 20,
      render: (row, opt) => (
        <Typography
          color="gray700"
          fontSize={10}
          fontWeight={opt?.isLast ? 700 : 500}
          variant="main10Bold"
        >
          {row.value || (opt?.isLast && !row.value)
            ? formatNumbers(Math.round(Number(row.value)))
            : ""}
        </Typography>
      ),
    },
    {
      align: "right",
      title: "",
      width: 10,
      render: (row, opt) => (
        <Typography
          fontSize={10}
          fontWeight={opt?.isLast ? 700 : 500}
          variant={opt?.isLast ? "main10Bold" : "gray12"}
        >
          {row.percent || (opt?.isLast && !row.percent)
            ? `${Math.round(Number(row.percent))}%`
            : ""}
        </Typography>
      ),
    },
  ];

  return (
    <Box
      bgcolor="white"
      borderRadius={dashboardHasKpiCard ? "none" : 2}
      boxShadow={dashboardHasKpiCard ? "none" : 1}
      pt={dashboardHasKpiCard ? { xs: 1.5, md: 0 } : 1.5}
      px={dashboardHasKpiCard ? { xs: 0, md: 1.5 } : 1.5}
      sx={{
        display: { xs: isForDesktop ? "none" : "", md: "inline-block" },
        height: { xs: 230, md: 180 },
        pb: { xs: 1.5, md: 1 },
        width: dashboardHasKpiCard ? "100%" : "90%",
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" mb={0.5}>
            <Typography variant="gray14Bold">{ts(`${kpiName}`)}</Typography>
            <Box alignItems="center" color={isWithoutData ? "gray600.main" : "main"} display="flex">
              <TargetIcon height={19} width={19} />
              {!isWithoutData && (
                <Box ml={1}>
                  <Typography fontSize={18} variant="main28">
                    {splitValue[0]}
                    {splitValue[1] ? "." : ""}
                  </Typography>
                  <Typography variant="main">{splitValue[1]}</Typography>
                  {kpi?.isActive &&
                    (kpiTarget2TypeSwitchedOn ? (
                      kpi?.target2 && !kpiHiddenPlanned?.includes(kpi.name) && !isFiltration ? ( // TECH DEBT - BA-706
                        <Typography variant="gray14Bold">/{formatNumbers(kpi?.target2)}</Typography>
                      ) : null
                    ) : kpi?.target && !kpiHiddenPlanned?.includes(kpi.name) && !isFiltration ? (
                      <Typography variant="gray14Bold">/{formatNumbers(kpi?.target)}</Typography>
                    ) : null)}
                </Box>
              )}
            </Box>
          </Box>
          <Box mb={0.5} mt={1}>
            <LinearProgress
              sx={{ borderRadius: 2 }}
              value={!kpiHiddenPlanned?.includes(kpi?.name) && !isFiltration ? percentReached : 0}
              variant="determinate"
            />
          </Box>

          {isWithoutData ? (
            <NoDataFound height={"calc(100% - 40px)"} kpi={t("events.thisKPI")} />
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index} sx={{ height: "20.81px" }}>
                    {kpiColumns.map((column, i) => (
                      <TableCell
                        key={i}
                        sx={{
                          minWidth: column.width,
                          padding: { xs: "4px 0", md: 0 },
                          textAlign: column.align,
                          borderBottom: index + 1 === data.length ? "none" : "1px solid #E3E3E3",
                        }}
                      >
                        {column.render(row, { isLast: index + 1 === data.length && showOverall })}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </>
      )}
    </Box>
  );
};
