import { format, isSameDay, isWithinInterval, parseISO } from "date-fns";

import { EventStatus } from "@/pages/Private/pages/Events/types";
import { EventDatesType } from "@/pages/Private/pages/Events/event.schema";

const dateFormat = "dd.MM.yyyy";

export const formatEventDates = ({
  startDate,
  endDate,
  isOneDayEvent,
}: {
  startDate: string;
  endDate: string;
  isOneDayEvent?: boolean;
}) => {
  if (!startDate || !endDate) {
    return "-";
  }

  if (isOneDayEvent) {
    return `${format(new Date(startDate), dateFormat)}`;
  }

  if (new Date(startDate).getMonth() === new Date(endDate).getMonth()) {
    return `${format(new Date(startDate), "dd")}.-${format(new Date(endDate), dateFormat)}`;
  }

  return `${format(new Date(startDate), "dd.MM")} - ${format(new Date(endDate), dateFormat)}`;
};

export const renderEventColor = (status: EventStatus) => {
  switch (status) {
    case EventStatus.LIVE:
      return {
        light: "statuses.live.light",
        medium: "statuses.live.medium",
        dark: "statuses.live.dark",
      };
    case EventStatus.UPCOMING:
      return {
        light: "statuses.upcoming.light",
        medium: "statuses.upcoming.medium",
        dark: "statuses.upcoming.dark",
      };
    case EventStatus.FINISHED:
      return {
        light: "statuses.finished.light",
        medium: "statuses.finished.medium",
        dark: "statuses.finished.dark",
      };

    default:
      break;
  }
};

export const formatNumbers = (value: number | string) => {
  const formattedValue = (value || 0).toString();

  if (formattedValue.length > 1 && formattedValue[0] === "0") {
    return formattedValue
      .slice(1)
      .replaceAll(/\./g, "")
      .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return formattedValue.replaceAll(/\./g, "").replaceAll(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const removeThousandsSeparator = (formattedNum: number | string): number => {
  return Number(formattedNum?.toString().replaceAll(/\./g, ""));
};

export const toPercent = ({ number, total }: { number: number; total: number }) => {
  return (number / total) * 100;
};

export const convertHourToMinutes = (hourString: string): number => {
  const [hour, minute] = hourString.split(":").map(Number);

  return hour * 60 + minute;
};

export const isArrayOfIntegersDiff = (array1: number[], array2: number[]) => {
  if (array1.length !== array2.length) return true;

  const sortedArray1 = [...array1].sort((a, b) => a - b);
  const sortedArray2 = [...array2].sort((a, b) => a - b);

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) return true;
  }

  return false;
};

/**
 * From passed in date extracts year month and day date from the browser locale and returns date string
 * @param inputDate Date
 * @returns returns date string yyyy-MM-dd e.g. 2024-02-19
 */
export const parseDateToISODateFormat = (inputDate?: Date): string => {
  if (!inputDate) return "";

  const year = inputDate.getFullYear();
  const month =
    inputDate.getMonth() + 1 > 9 ? inputDate.getMonth() + 1 : `0${inputDate.getMonth() + 1}`;
  const day = inputDate.getDate() > 9 ? inputDate.getDate() : `0${inputDate.getDate()}`;

  return `${year}-${month}-${day}`;
};

/**
 * Filters event dates within the interval of the passed start and end date
 * @param eventDates Array with event dates
 * @param startDate Date string
 * @param endDate Date string
 * @param isAlsoFilterByDataAvailability Boolean if true will also filter dates with more than 0 as their dataAvailabilityCount value
 * @returns Array with filtered event dates
 */
export const filterEventDates = ({
  eventDates,
  startDate,
  endDate,
  isAlsoFilterByDataAvailability,
}: {
  eventDates: EventDatesType[];
  startDate: string;
  endDate: string;
  isAlsoFilterByDataAvailability?: boolean;
}) => {
  const start = parseISO(startDate);
  const end = parseISO(endDate);

  return eventDates.filter((ed) => {
    const dataAvailabilityFilter = isAlsoFilterByDataAvailability
      ? ed.dataAvailabilityCount && true
      : true;
    const eventDate = parseISO(ed.fromDateTime);
    const isStartDate = isSameDay(eventDate, start);
    const isEndDate = isSameDay(eventDate, end);
    const isInRange = isWithinInterval(eventDate, { start, end });

    return dataAvailabilityFilter && (isStartDate || isEndDate || isInRange);
  });
};
