import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/app/redux";

const initialState = {
  isCompleted: false,
  stepIndex: 0,
  steps: [],
};

export const joyrideSlice = createSlice({
  name: "joyride",
  initialState,
  reducers: {
    setIsCompleted: (state, action) => {
      state.isCompleted = action.payload;
    },
    setStepIndex: (state, action) => {
      state.stepIndex = action.payload;
    },
    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    resetJoyride: () => initialState,
  },
});

export const { setIsCompleted, setStepIndex, setSteps, resetJoyride } = joyrideSlice.actions;
export const joyrideReducer = joyrideSlice.reducer;

export const joyrideSelector = (state: RootState) => state.joyride;
