import { useTheme } from "@mui/material";
import { FunctionComponent } from "react";

export const DynamicReporting: FunctionComponent = () => {
  const theme = useTheme();
  const authToken = localStorage.getItem("token");

  const grafanaDashboardURL = `https://dev.bsure-analytics.com/grafana/d/ce1jvbq6v4fswc/ploom-expert?orgId=1&auth_token=${authToken}&theme=light`;
  // const grafanaDashboardURL = `${process.env.REACT_APP_API_URL}/grafana/d/ce1jvbq6v4fswc`;

  return (
    <iframe
      className="w-full h-full border-none"
      src={grafanaDashboardURL}
      style={{
        border: "2px solid",
        borderRadius: "15px",
        borderColor: theme.palette.main,
        minHeight: "100vh",
        width: "100%",
      }}
      title="Dynamic Reporting"
    />
  );
};
