import { useTranslation } from "react-i18next";
import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { getDay } from "date-fns";

import { DonutChart, Loader } from "@/components";
import { toPercent } from "@/app/utils";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface OSSByDayProps {
  isFiltration?: boolean;
  isFetching: boolean;
  ossKPIs: KpiType[];
}

export const OSSByDayDonutChart: FunctionComponent<OSSByDayProps> = ({
  isFiltration,
  isFetching,
  ossKPIs,
}) => {
  const { t } = useTranslation();

  const ossByDayLabels = useMemo(() => {
    const ossByDayKPIs = ossKPIs
      .find((kpi) => kpi.name === KpiTypeEnum.ONE_STICK_SAMPLING)
      ?.data.at(0);

    return (
      ossByDayKPIs?.date?.reduce((weekDays: string[], kpi) => {
        const currDateArr = kpi.date.split("-");
        const currDayWeekIndex = getDay(
          new Date(+currDateArr[0], +currDateArr[1] - 1, +currDateArr[2])
        );
        const currWeekDayName = t(`weekDays.${currDayWeekIndex}`);

        weekDays.push(currWeekDayName);

        return weekDays;
      }, []) || []
    );
  }, [ossKPIs, t]);

  const ossByDayData = useMemo(() => {
    const ossByDayKPIs = ossKPIs
      .filter((kpi) => kpi.name === KpiTypeEnum.ONE_STICK_SAMPLING)
      .at(0)
      ?.data.at(0);

    const ossByDayValues = ossByDayKPIs?.date?.map((day) => day.value) ?? [];
    const total = ossByDayValues.reduce((a, b) => a + b, 0);
    const percents = ossByDayValues.map((v) => Math.round(toPercent({ number: v, total })));

    return percents;
  }, [ossKPIs]);

  return isFiltration ? (
    <></>
  ) : (
    <Box
      sx={{
        borderStyle: "solid",
        borderWidth: { xs: "0 0 1px 0", md: "0 1px 0 0" },
        borderColor: "gray400.main",
        paddingX: { xs: 0, md: 1 },
        paddingY: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutOSSByDay")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart kpi={t("events.donutOSSByDay")} labels={ossByDayLabels} series={ossByDayData} />
      )}
    </Box>
  );
};
