import { FunctionComponent, useEffect, useMemo } from "react";
import { useTheme } from "@mui/material";
import Joyride, { CallBackProps, EVENTS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import {
  setStepIndex,
  setSteps,
  setIsCompleted,
  joyrideSelector,
} from "@/pages/Private/redux/joyride.slice";
import { EventTypes } from "@/pages/Private/pages/Events/types";
import { authSelector, setUser } from "@/pages/Public/redux/auth.slice";
import { Role } from "@/enum";
import { useUpdateUserGuideMutation } from "@/pages/Private/redux";
import { store } from "@/app/redux";

interface OnboardingTourProps {
  isLoading?: boolean;
  eventType?: EventTypes;
}

export const OnboardingTour: FunctionComponent<OnboardingTourProps> = ({
  eventType,
  isLoading,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id } = useParams();

  const { isCompleted, stepIndex, steps } = useSelector(joyrideSelector);
  const { user, token } = useSelector(authSelector);

  const [updateUserGuide] = useUpdateUserGuideMutation();

  const pageURLsMap = {
    "/app/events": "events",
    [`/app/events/${id}`]: "eventDetails",
    [`/app/events/${id}/settings`]: "eventSettings",
  };

  const currentPage = pageURLsMap[pathname];

  const pageSteps = useMemo(() => {
    const getContentByRole = (target: string, defaultContent: string) => {
      const roleSpecificContent: Record<string, Record<string, string>> = {
        [Role.TENANCY_ADMIN]: {
          ".navigation-menu":
            "Through the navigation menu you can switch between the different main application sections. The menus will help you easily manage users or create, view and configure your brands, modules, projects & data sources.",
        },
        [Role.AGENCY_ADMIN]: {
          ".navigation-menu":
            "Through the navigation menu you can switch between the different main application sections. The menus will help you easily manage users or create, view and configure your brands, modules, projects & data sources.",
        },
        [Role.AGENCY_USER]: {
          ".navigation-menu":
            "Through the navigation menu you can switch between the different main application sections. The menus will help you easily view all KPIs data for events and brands for different projects.",
        },
      };

      return (
        roleSpecificContent[user?.roles.at(0)?.code || Role.AGENCY_USER]?.[target] || defaultContent
      );
    };

    return {
      "/app/events": [
        {
          title: "Welcome to FRET Event Analytics",
          target: "body",
          content:
            "This guide will help you explore the dashboards and some of its key functionalities.",
          placement: "center",
          hideCloseButton: "true",
          disableBeacon: "true",
        },
        {
          target: ".events-table",
          content:
            "Table that lists all events, where each row represents a specific event with its details.",
          placementBeacon: "top",
          disableScrolling: "true",
        },
        {
          target: ".total-kpi-cards",
          content:
            "These tiles show the total amounts of actual values compared to the target ones for each individual KPI for all events.",
          placementBeacon: "top",
          disableScrolling: "true",
        },
        {
          target: ".event-filters",
          content:
            "You can use these filters to narrow down the shown data by brand, type or project for more focused analysis.",
          disableScrolling: "true",
        },
        {
          target: ".navigation-menu",
          content: getContentByRole(
            ".navigation-menu",
            "Through the navigation menu you can switch between the different main application sections. The menus will help you easily manage users or create, view and configure your brands, modules, projects & data sources."
          ),
          disableScrolling: "true",
        },
      ],
      [`/app/events/${id}`]: [
        {
          target: ".dashboard-navigation",
          content:
            "Selecting a specific KPI type dashboard can help you see data analysis on more granular level represented with different tiles and charts.",
          placementBeacon: "bottom",
          disableScrolling: "true",
        },
        {
          target: ".event-settings-button",
          content: `You can use this button for more advanced event settings management, such as event details, ${
            eventType === EventTypes.MOBILE ? "tour plan" : "modules"
          } and KPIs.`,
          disableScrolling: "true",
        },
      ],
      [`/app/events/${id}/settings`]: [
        {
          target: ".event-settings-tabs",
          content:
            "The main event settings that can be configured are represented with these menu tabs.",
          disableScrolling: "true",
        },
      ],
    };
  }, [id, user?.roles, eventType]);

  useEffect(() => {
    const currentSteps = pageSteps[pathname as keyof typeof pageSteps] || [];

    dispatch(setStepIndex(user?.guide[currentPage as keyof typeof user.guide]?.stepIndex));
    dispatch(setIsCompleted(user?.guide[currentPage as keyof typeof user.guide]?.isCompleted));
    dispatch(setSteps(currentSteps));
  }, [pathname, dispatch, currentPage, user, pageSteps]);

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { action, type, lifecycle = {} } = data;

    try {
      if (action === "skip" || type === "tour:end") {
        dispatch(setIsCompleted(true));
      } else if (action === "next" && EVENTS.STEP_AFTER === type) {
        dispatch(setStepIndex(stepIndex + 1));
      } else if (action === "prev" && EVENTS.STEP_AFTER === type) {
        dispatch(setStepIndex(stepIndex - 1));
      }

      const latestState = joyrideSelector(store.getState());

      const updatedGuide = {
        ...user?.guide,
        [currentPage]: {
          stepIndex: latestState.stepIndex,
          isCompleted: latestState.isCompleted,
        },
      };

      const body = {
        guide: updatedGuide,
      };

      if (lifecycle === "tooltip" || type === "tour:end") {
        const response = await updateUserGuide({ body }).unwrap();

        dispatch(setUser({ access_token: token, user: { ...user, guide: response } }));
      }
    } catch (error) {
      console.error("Error updating user guide steps:", error);
    }
  };

  return (
    <>
      {true && (
        <Joyride
          continuous
          disableCloseOnEsc
          disableOverlayClose
          showProgress
          showSkipButton
          callback={handleJoyrideCallback}
          run={!isLoading && !isCompleted}
          scrollOffset={50}
          stepIndex={stepIndex}
          steps={steps}
          styles={{
            options: {
              zIndex: 1000,
              primaryColor: theme.palette.main,
            },
          }}
        />
      )}
    </>
  );
};
