import { useTranslation } from "react-i18next";
import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface PackSamplingSplitByBrandProps {
  isFiltration?: boolean;
  isFetching: boolean;
  packSamplingKPIs: KpiType[];
}

export const PackSamplingByBrandDonutChart: FunctionComponent<PackSamplingSplitByBrandProps> = ({
  isFiltration,
  isFetching,
  packSamplingKPIs,
}) => {
  const { t } = useTranslation();

  const packSamplingByBrandLabels = useMemo(() => {
    const packSamplingByBrandKPIs =
      packSamplingKPIs.find((kpi) => kpi.name === KpiTypeEnum.PACK_SAMPLING_BY_BRAND)?.data ?? [];

    return packSamplingByBrandKPIs.map((kpi) => kpi.specific);
  }, [packSamplingKPIs]);

  const packSamplingByBrandData = useMemo(() => {
    const packSamplingByBrandKPIs =
      packSamplingKPIs.find((kpi) => kpi.name === KpiTypeEnum.PACK_SAMPLING_BY_BRAND)?.data ?? [];

    return packSamplingByBrandLabels.map((type) => {
      const kpiData = packSamplingByBrandKPIs.find((kpi) => kpi.specific === type);

      if (kpiData) {
        if (isFiltration) {
          return Math.round(kpiData.date?.[0]?.percent ?? 0);
        } else {
          return Math.round(kpiData.overall?.percent ?? 0);
        }
      } else {
        return 0;
      }
    });
  }, [packSamplingKPIs, isFiltration, packSamplingByBrandLabels]);

  return (
    <Box
      sx={{
        borderStyle: "solid",
        borderWidth: { xs: "0 0 1px 0", md: "0 1px 0 0" },
        borderColor: "gray400.main",
        paddingRight: { xs: 0, md: 1 },
        paddingBottom: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutSalesByBrand")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutSalesByBrand")}
          labels={packSamplingByBrandLabels}
          series={packSamplingByBrandData}
        />
      )}
    </Box>
  );
};
