import { useTranslation } from "react-i18next";
import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface OSSByCategoryProps {
  isFiltration?: boolean;
  isFetching: boolean;
  ossKPIs: KpiType[];
}

export const OSSByCategoryDonutChart: FunctionComponent<OSSByCategoryProps> = ({
  isFetching,
  ossKPIs,
}) => {
  const { t } = useTranslation();

  const ossByCategoryLabels = useMemo(() => {
    const ossByCategoryKPIs =
      ossKPIs.find((kpi) => kpi.name === KpiTypeEnum.OSS_BY_CATEGORY)?.data ?? [];

    return ossByCategoryKPIs.map((kpi) => kpi.specific);
  }, [ossKPIs]);

  const ossByCategoryData = useMemo(() => {
    const ossByCategoryKPIs =
      ossKPIs.find((kpi) => kpi.name === KpiTypeEnum.OSS_BY_CATEGORY)?.data ?? [];

    return ossByCategoryLabels.map((type) => {
      const kpiData = ossByCategoryKPIs.find((kpi) => kpi.specific === type);

      if (kpiData) {
        return Math.round(kpiData.date?.[0]?.percent ?? 0);
      } else {
        return 0;
      }
    });
  }, [ossKPIs, ossByCategoryLabels]);

  return (
    <Box
      sx={{
        paddingLeft: { xs: 0, md: 1 },
        paddingY: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutOSSByCategory")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutOSSByCategory")}
          labels={ossByCategoryLabels}
          series={ossByCategoryData}
        />
      )}
    </Box>
  );
};
