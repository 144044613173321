import { Box, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";

import { useAppSelector } from "@/app/redux/hooks";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { eventsSelector } from "@/pages/Private/redux";
import { Role } from "@/enum";
import { useWindowSize } from "@/app/hooks/useWindowSize";

import { routes } from "./config";
import { SelectField } from "../SelectField/SelectField";

const navItemStyles = {
  textAlign: "center",
  fontWeight: 700,
  fontSize: "14px",
  width: "max-content",
};

const MenuItem: FunctionComponent<{
  text: ReactNode;
  path: string;
  isActive?: boolean;
  dashboardFeature: string;
  isVisibleForEventType?: boolean;
}> = ({ text, path, isActive, dashboardFeature, isVisibleForEventType }) => {
  const isDashboardActive = useIsFeatureActive(dashboardFeature);

  return (isDashboardActive || dashboardFeature === "default") && isVisibleForEventType ? (
    <ListItem key={text?.toString()} disablePadding sx={{ width: "auto" }}>
      <ListItemButton
        component={Link}
        sx={{
          color: isActive ? "main" : "gray600.main",
          paddingX: { xs: 1, md: 2 },
          ...navItemStyles,
        }}
        to={path}
      >
        <ListItemText disableTypography primary={text} />
      </ListItemButton>
    </ListItem>
  ) : (
    <></>
  );
};
export const RibbonMenu: FunctionComponent<{
  isProjectReporting?: boolean;
}> = ({ isProjectReporting }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    routes.userEventDetailsDashboards.at(0)?.path
  );

  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { dashboard } = useParams();
  const { user } = useAppSelector(authSelector);
  const { eventDetails } = useAppSelector(eventsSelector);
  const [userPermittedToSeePromoters, setUserPermittedToSeePromoters] = useState(false);
  const isUserBsureAdmin = user?.roles.some((role) => role.code === Role.BSURE_ADMIN);

  const { register, watch } = useForm({
    defaultValues: {
      dashboard: "overview",
    },
  });

  const dashboardsOptions = [
    ...routes.userEventDetailsDashboards,
    ...(userPermittedToSeePromoters || isUserBsureAdmin ? routes.adminEventDetailsDashboards : []),
  ]
    .filter((route) =>
      eventDetails?.eventType
        ? route.visibleForEventTypes.includes(eventDetails.eventType)
        : isProjectReporting
    )
    .map(({ text, path }) => ({ text, value: path }));

  const { dashboard: dropdownSelectedDashboard } = watch();

  useEffect(() => {
    const groupIds = user?.agency_ids || [];
    const isGroupOfUserAndEventSame = groupIds.includes(eventDetails?.agencyId || 0);
    const isUserAgencyAdmin = user
      ? user.roles.some((role) => role.code === Role.AGENCY_ADMIN)
      : false;

    setUserPermittedToSeePromoters(isGroupOfUserAndEventSame && isUserAgencyAdmin);
  }, [user, eventDetails]);

  useEffect(() => {
    if (dashboard) {
      setSelectedMenuItem(dashboard);
    }

    if (width < 600 && dropdownSelectedDashboard) {
      navigate(dropdownSelectedDashboard);
    }
  }, [dashboard, navigate, dropdownSelectedDashboard, width]);

  return width < 600 ? (
    <Box sx={{ width: { xs: "100%", md: 280 }, mb: 1 }}>
      <SelectField
        options={dashboardsOptions}
        withDefaultValue={false}
        {...register("dashboard")}
        currentValue={dropdownSelectedDashboard}
      />
    </Box>
  ) : (
    <Box className="dashboard-navigation" sx={{ display: "flex", justifyContent: "center" }}>
      {routes.userEventDetailsDashboards.map((route, i) => (
        <MenuItem
          key={`event-dashboard-${i}`}
          dashboardFeature={route.dashboardFeature}
          isActive={route.path === selectedMenuItem}
          isVisibleForEventType={
            eventDetails?.eventType
              ? route.visibleForEventTypes.includes(eventDetails.eventType)
              : isProjectReporting
          }
          path={route.path}
          text={route.text}
        />
      ))}
      {(userPermittedToSeePromoters || isUserBsureAdmin) &&
        routes.adminEventDetailsDashboards.map((route, i) => (
          <MenuItem
            key={`event-dashboard-${i}`}
            dashboardFeature={route.dashboardFeature}
            isActive={route.path === selectedMenuItem}
            isVisibleForEventType={
              eventDetails?.eventType
                ? route.visibleForEventTypes.includes(eventDetails.eventType)
                : isProjectReporting
            }
            path={route.path}
            text={route.text}
          />
        ))}
    </Box>
  );
};
