export enum Dashboard {
  OVERVIEW = "overview",
  SALES = "sales",
  DOI = "doi",
  ACTIVATIONS = "activations",
  PACK_SAMPLING = "pack-sampling",
  GIVEAWAYS = "giveaways",
  OSS = "oss",
  PROMOTER = "promoter",
}
