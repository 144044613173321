import { MetaData } from "@/schemas/meta-data";

import { EventType } from "./event.schema";

export enum KpiTypeEnum {
  DEFAULT = "",
  BSCORE = "BSCORE",
  BIMPACT = "BIMPACT",
  MALE = "MALE",
  FEMALE = "FEMALE",
  VISITORS = "VISITORS",
  VISITORS_2 = "VISITORS_2", // TECH DEBT - BA-706
  NEWSLETTER_DATA = "NEWSLETTER_DATA",
  NEWSLETTER_DATA_WITH_DEVICE_ID = "NEWSLETTER_DATA_WITH_DEVICE_ID",
  NEWSLETTER_DATA_2 = "NEWSLETTER_DATA_2", // TECH DEBT - BA-706
  NEWSLETTER_DATA_BY_AGEGROUPS = "NEWSLETTER_DATA_BY_AGEGROUPS",
  NEWSLETTER_DATA_BY_GENDER = "NEWSLETTER_DATA_BY_GENDER",
  NEWSLETTER_DATA_BY_AGEGROUP_BY_GENDER = "NEWSLETTER_DATA_BY_AGEGROUP_BY_GENDER",
  NEWSLETTER_DATA_BY_CITY = "NEWSLETTER_DATA_BY_CITY",
  NEWSLETTER_DATA_BY_PROMOTEREMAIL = "NEWSLETTER_DATA_BY_PROMOTEREMAIL",
  ACTIVATIONS = "ACTIVATIONS",
  ACTIVATIONS_2 = "ACTIVATIONS_2", // TECH DEBT - BA-706
  SALES = "SALES",
  SALES_2 = "SALES_2", // TECH DEBT - BA-706
  SAMPLES = "SAMPLES",
  SAMPLES_2 = "SAMPLES_2", // TECH DEBT - BA-706
  EMOTIONS = "EMOTIONS",
  LIVIA_DURATION = "LIVIA_DURATION",
  GIVEAWAY = "GIVEAWAY",
  GIVEAWAY_2 = "GIVEAWAY_2", // TECH DEBT - BA-706
  GIVEAWAY_BY_MODULE = "GIVEAWAY_BY_MODULE",
  IMPACT = "IMPACT",
  PROMOTER_HOURS = "PROMOTER_HOURS",
  PROMOTER_HOURS_2 = "PROMOTER_HOURS_2", // TECH DEBT - BA-706
  RESIDENCE_TIME = "RESIDENCE_TIME",
  BEACON_INTERACTIONS = "BEACON_INTERACTIONS",
  OCCUPANCY = "OCCUPANCY",
  FOOTFALL = "FOOTFALL",
  SALES_20_STICK_PACKAGES = "SALES_20_STICK_PACKAGES",
  SALES_REAL_PACKAGES = "SALES_REAL_PACKAGES",
  SALES_REAL_EVO_PACKAGES = "SALES_REAL_EVO_PACKAGES",
  SALES_BY_PRODUCT = "SALES_BY_PRODUCT",
  SALES_BY_BRAND_BY_PRODUCT = "SALES_BY_BRAND_BY_PRODUCT",
  SALES_BY_BRAND = "SALES_BY_BRAND",
  SALES_BY_MODULE = "SALES_BY_MODULE",
  SALES_BY_MODULE_BY_PRODUCT = "SALES_BY_MODULE_BY_PRODUCT",
  SALES_BY_BRANDFAMILY_BY_PRODUCT = "SALES_BY_BRANDFAMILY_BY_PRODUCT",
  SALES_BY_CATEGORY = "SALES_BY_CATEGORY",
  PACK_SAMPLING_20_STICK_PACKAGES = "PACK_SAMPLING_20_STICK_PACKAGES",
  PACK_SAMPLING_REAL_PACKAGES = "PACK_SAMPLING_REAL_PACKAGES",
  PACK_SAMPLING_BY_PRODUCT = "PACK_SAMPLING_BY_PRODUCT",
  PACK_SAMPLING_BY_BRAND = "PACK_SAMPLING_BY_BRAND",
  PACK_SAMPLING_BY_MODULE = "PACK_SAMPLING_BY_MODULE",
  PACK_SAMPLING_BY_MODULE_BY_PRODUCT = "PACK_SAMPLING_BY_MODULE_BY_PRODUCT",
  PACK_SAMPLING_BY_BRANDFAMILY_BY_PRODUCT = "PACK_SAMPLING_BY_BRANDFAMILY_BY_PRODUCT",
  PACK_SAMPLING_BY_CATEGORY = "PACK_SAMPLING_BY_CATEGORY",
  SALES_BY_PRODUCT_TYPE_BY_CATEGORY_BY_PRODUCT = "SALES_BY_PRODUCT_TYPE_BY_CATEGORY_BY_PRODUCT",
  SALES_BY_PRODUCT_TYPE_BY_CATEGORY = "SALES_BY_PRODUCT_TYPE_BY_CATEGORY",
  SALES_BY_PRODUCT_BY_HEATERDEVICE = "SALES_BY_PRODUCT_BY_HEATERDEVICE",
  SALES_BY_PRODUCT_BY_CIGARETTESTYPE_ECSCATEGORY = "SALES_BY_PRODUCT_BY_CIGARETTESTYPE_ECSCATEGORY",
  SALES_BY_ACCESSORIES_FUNCTIONAL_BY_PRODUCT = "SALES_BY_ACCESSORIES_FUNCTIONAL_BY_PRODUCT",
  SALES_BY_ACCESSORIES_DEVICE_BY_PRODUCT = "SALES_BY_ACCESSORIES_DEVICE_BY_PRODUCT",
  SALES_BY_DEVICE = "SALES_BY_DEVICE",
  SALES_BY_CIGARETTESTYPE_ECSCATEGORY = "SALES_BY_CIGARETTESTYPE_ECSCATEGORY",
  ONE_STICK_SAMPLING = "ONE_STICK_SAMPLING",
  OSS_BY_BRAND = "OSS_BY_BRAND",
  OSS_BY_DAY = "OSS_BY_DAY",
  OSS_BY_CATEGORY = "OSS_BY_CATEGORY",
  OSS_BY_BRANDFAMILY_BY_PRODUCT = "OSS_BY_BRANDFAMILY_BY_PRODUCT",
  OSS_BY_PRODUCT = "OSS_BY_PRODUCT",
  OSS_BY_MODULE = "OSS_BY_MODULE",
  OSS_BY_MODULE_BY_PRODUCT = "OSS_BY_MODULE_BY_PRODUCT",
}

export enum BudgetTypeEnum {
  WKZ = "WKZ",
  EXECUTIONS = "EXECUTIONS",
  MODULAUFBAUKOSTEN = "MODULAUFBAUKOSTEN",
  FILM_AND_FOTO = "FILM_AND_FOTO",
}

export enum EventStatus {
  LIVE = "LIVE",
  UPCOMING = "UPCOMING",
  FINISHED = "FINISHED",
}
export enum EventTypes {
  FESTIVAL = "FESTIVAL",
  PLOOM_FESTIVAL = "PLOOM_FESTIVAL",
  STATIONARY = "STATIONARY",
  MOBILE = "MOBILE",
}

export enum Ages {
  RANGE_0_18 = "RANGE_0_18",
  RANGE_18_19 = "RANGE_18_19",
  RANGE_20_29 = "RANGE_20_29",
  RANGE_30_39 = "RANGE_30_39",
  RANGE_40_49 = "RANGE_40_49",
  RANGE_50_59 = "RANGE_50_59",
  RANGE_60 = "RANGE_60",
}

export enum Duration {
  LIVIA_DURATION_20 = "LIVIA_DURATION_20",
  LIVIA_DURATION_5_20 = "LIVIA_DURATION_5_20",
  LIVIA_DURATION_1_5 = "LIVIA_DURATION_1_5",
}

export enum Emotions {
  POSITIVE = "POSITIVE",
  NEUTRAL = "NEUTRAL",
  NEGATIVE = "NEGATIVE",
}

export enum DirectionType {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

export enum NewsletterSpecifics {
  DOI_NL = "DOI_NL",
  DOI_NL_WITH_DEVICE_ID = "DOI_NL_WITH_DEVICE_ID",
  DOI = "DOI",
  DOI_WITH_DEVICE_ID = "DOI_WITH_DEVICE_ID",
  PENDING = "PENDING",
  ANONYMOUS = "ANONYMOUS",
}
export enum NewsletterWithoutDeviceSpecifics {
  DOI_NL = "DOI_NL",
  DOI = "DOI",
  PENDING = "PENDING",
  ANONYMOUS = "ANONYMOUS",
}
export enum NewsletterWithDeviceSpecifics {
  DOI_NL_WITH_DEVICE_ID = "DOI_NL_WITH_DEVICE_ID",
  DOI_WITH_DEVICE_ID = "DOI_WITH_DEVICE_ID",
  PENDING_WITH_DEVICE_ID = "PENDING_WITH_DEVICE_ID",
  ANONYMOUS = "ANONYMOUS",
}

export enum SalesPloomProductTypes {
  DEVICE = "Heater",
  CIGARETTES = "Cigarettes",
  ACCESSORIES = "Accessoires",
}

export enum SalesPloomProductCategories {
  Heater = "Devices",
  ECS = "Evo Packs",
  FUNCTIONAL = "Func. Acc.",
  DEVICE = "Dev. Acc.",
}

export interface EventState {
  events: EventType[];
  selectedEvent: EventType | null;
  eventDetails: EventType | null;
  regions: string[] | null;
  kpiTarget2TypeSwitchedOn: boolean; // TECH DEBT - BA-706
  filters: {
    brandId: number | null;
    eventTypeIds: string[];
    projectId: number | null;
  };
}

export interface EventsResponse {
  data: EventType[];
  meta: MetaData;
}

export interface EventsOverallKPIDataResponse {
  kpi: KpiTypeEnum;
  sum: number;
  specific: string | null;
  goal: number;
}
export interface EventsOverallKPIResponse {
  data: EventsOverallKPIDataResponse[];
  kpiGoals: {
    [key: string]: number;
  };
}
export interface KpiTileData {
  name: string;
  value: number | string;
  percent: number | string;
}

export interface TopTenTableData {
  name: string | null;
  quantity: number | string;
  percentage: number;
}
