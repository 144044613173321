import { useTranslation } from "react-i18next";
import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface OSSSplitByBrandProps {
  isFiltration?: boolean;
  isFetching: boolean;
  ossKPIs: KpiType[];
}

export const OSSByBrandDonutChart: FunctionComponent<OSSSplitByBrandProps> = ({
  isFiltration,
  isFetching,
  ossKPIs,
}) => {
  const { t } = useTranslation();

  const ossByBrandLabels = useMemo(() => {
    const ossByBrandKPIs = ossKPIs.find((kpi) => kpi.name === KpiTypeEnum.OSS_BY_BRAND)?.data ?? [];

    return ossByBrandKPIs.map((kpi) => kpi.specific);
  }, [ossKPIs]);

  const ossByBrandData = useMemo(() => {
    const ossByBrandKPIs = ossKPIs.find((kpi) => kpi.name === KpiTypeEnum.OSS_BY_BRAND)?.data ?? [];

    return ossByBrandLabels.map((type) => {
      const kpiData = ossByBrandKPIs.find((kpi) => kpi.specific === type);

      if (kpiData) {
        if (isFiltration) {
          return Math.round(kpiData.date?.[0]?.percent ?? 0);
        } else {
          return Math.round(kpiData.overall?.percent ?? 0);
        }
      } else {
        return 0;
      }
    });
  }, [ossKPIs, isFiltration, ossByBrandLabels]);

  return (
    <Box
      sx={{
        borderStyle: "solid",
        borderWidth: { xs: "0 0 1px 0", md: "0 1px 0 0" },
        borderColor: "gray400.main",
        paddingRight: { xs: 0, md: 1 },
        paddingBottom: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutOSSByBrand")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutOSSByBrand")}
          labels={ossByBrandLabels}
          series={ossByBrandData}
        />
      )}
    </Box>
  );
};
