import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { ColumnBarChart } from "@/components/ColumnBarChart/ColumnBarChart";
import { Loader, NoDataFound } from "@/components";
import { convertChartStringData } from "@/app/utils";

import { EventDataType, KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface OSSProductsByBrandProps {
  isFiltration?: boolean;
  isFetching: boolean;
  ossKPIs: KpiType[];
}

export const OSSProductsByBrand: FunctionComponent<OSSProductsByBrandProps> = ({
  isFiltration,
  isFetching,
  ossKPIs,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.ossKPIs.byProductByBrand.${key}`);

  const [maxValue, setMaxValue] = useState(0);

  const ossByBrandByProductKPIs = ossKPIs.find(
    (kpi) => kpi.name === KpiTypeEnum.OSS_BY_BRANDFAMILY_BY_PRODUCT
  )?.data;

  //This converts the KPI string values into proper Apex chart input
  const convertNameValues = useCallback(
    (data: EventDataType[], arr: string[]) => {
      const values = convertChartStringData({ enumValues: arr, data, isFiltration });

      return values;
    },
    [isFiltration]
  );

  const productNames = useMemo(() => {
    const currEventProducts = ossByBrandByProductKPIs?.map((el) => el.specific) || [];

    return convertNameValues(
      ossByBrandByProductKPIs?.filter((data) => data.specific) || [],
      currEventProducts
    );
  }, [ossByBrandByProductKPIs, convertNameValues]);

  //This converts the KPI numeric values into proper Apex chart input
  const convertToStackedChartSeries = useCallback(
    ({
      enumValues,
      data,
    }: {
      enumValues: string[];
      data: EventDataType[];
      isFiltration?: boolean;
    }) => {
      const setSeriesDataArr = (data: EventDataType[], value: number, i: number) => {
        const series = Array.from({ length: data.length }, () => 0);

        //this calculates and sets the max value for the yaxis in the chart
        const totalOSS = data[i].date.reduce((totalOSSValue, oss) => {
          totalOSSValue += oss.value;

          return totalOSSValue;
        }, 0);

        if (maxValue < totalOSS) {
          setMaxValue(totalOSS);
        }

        series[i] = value;

        return series;
      };

      return enumValues.flatMap((name, index) =>
        data.flatMap((eventData) => {
          return name === eventData.specific
            ? eventData.date.map((prodData) =>
                Object({
                  name: prodData.dim2,
                  data: setSeriesDataArr(data, Math.floor(prodData.value), index),
                })
              )
            : [];
        })
      );
    },
    [maxValue]
  );

  const convertData = useCallback(
    (data: EventDataType[], arr: string[]) => {
      return convertToStackedChartSeries({ enumValues: arr, data, isFiltration });
    },
    [isFiltration, convertToStackedChartSeries]
  );

  const ossByBrandValues = useMemo(() => {
    const currEventProducts = ossByBrandByProductKPIs?.map((prod) => prod.specific) || [];

    return convertData(
      ossByBrandByProductKPIs?.map((prod) => {
        return {
          ...prod,
          date: prod.date.filter((data) => (isFiltration ? data.value : data.value && !data.date)),
        };
      }) || [],
      currEventProducts
    );
  }, [ossByBrandByProductKPIs, convertData, isFiltration]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: { xs: 3, md: 0 },
        mr: { xs: 0, md: 1 },
        bgcolor: { md: "white" },
        borderRadius: 2,
        boxShadow: 1,
        p: { xs: 1, md: 2 },
        width: { md: "70%" },
      }}
    >
      <Typography component="h3" variant="mainBold">
        {ts("title")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : ossByBrandByProductKPIs ? (
        <ColumnBarChart stacked categories={productNames} series={ossByBrandValues} />
      ) : (
        <NoDataFound height="136px" kpi={ts("title").toLowerCase()} />
      )}
    </Box>
  );
};
